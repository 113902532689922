import { UserProfile } from "../model/userProfile";
import { WorkflowStepType } from "../model/workflowStepType";

export class UserDto {

    name?: string;
    vorname?: string;
    userId: string;
    result: string;
    resultErrorMessage: string;
    userProfiles: UserProfile[];
    WorkflowStepType: WorkflowStepType;
}