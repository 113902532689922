import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';


// Service to do general http requests
@Injectable({
    providedIn: 'root',
})
export class HttpRequestService {

    // Counter
    private id: number = 0;

    // Current works in progress
    private currentRequests: number[] = [];

    // Is the service in a async call?
    get isWorking(): boolean {
        return this.currentRequests.length > 0;
    }


    // Constructor
    constructor(private http: HttpClient, private auth: AuthService) {
    }


    // Perform POST request
    public async Post<T>(endpoint: string, body: any, params: { [key: string]: string }, authToken: string, includeCredentials: boolean, acceptOnlyJson: boolean, isTrackedRequest: boolean, returnError: boolean): Promise<T> {

        // Create request
        let curId: number = this.id++;
        if (isTrackedRequest) {
            this.currentRequests.push(curId);
        }

        // Create header
        let httpHeader = this.CreateHeader(authToken, acceptOnlyJson);

        // Filter the parameters
        params = this.CreateParameter(params);

        // Call the endpoint
        let ret: T = null;
        try {
            ret = await this.http.post<T>(endpoint, body, { headers: httpHeader, params: params, withCredentials: includeCredentials }).toPromise();
        }
        // Return or log errors
        catch (error) {
            ret = this.HandleError(error, returnError);
        }

        // Delete request
        if (isTrackedRequest) {
            this.currentRequests = this.currentRequests.filter(x => x != curId);
        }

        // Return
        return ret;
    }


    // Perform GET request
    public async Get<T>(endpoint: string, params: { [key: string]: string }, authToken: string, includeCredentials: boolean, acceptOnlyJson: boolean, isTrackedRequest: boolean, returnError: boolean): Promise<T> {

        // Create request
        let curId: number = this.id++;
        if (isTrackedRequest) {
            this.currentRequests.push(curId);
        }

        // Create header
        let httpHeader = this.CreateHeader(authToken, acceptOnlyJson);

        // Filter the parameters
        params = this.CreateParameter(params);

        // Call the endpoint
        let ret: T = null;
        try {
            ret = await this.http.get<T>(endpoint, { headers: httpHeader, params: params, withCredentials: includeCredentials }).toPromise();
        }
        // Return or log errors
        catch (error) {
            ret = this.HandleError(error, returnError);
        }

        // Delete request
        if (isTrackedRequest) {
            this.currentRequests = this.currentRequests.filter(x => x != curId);
        }

        // Return
        return ret;
    }


    // Perform DELETE request
    public async Delete(endpoint: string, params: { [key: string]: string }, authToken: string, includeCredentials: boolean, acceptOnlyJson: boolean, isTrackedRequest: boolean, returnError: boolean) {

        // Create request
        let curId: number = this.id++;
        if (isTrackedRequest) {
            this.currentRequests.push(curId);
        }

        // Create header
        let httpHeader = this.CreateHeader(authToken, acceptOnlyJson);

        // Filter the parameters
        params = this.CreateParameter(params);

        // Call the endpoint
        try {
            await this.http.delete(endpoint, { headers: httpHeader, params: params, withCredentials: includeCredentials }).toPromise();
        }
        // Return or log errors
        catch (error) {
            return this.HandleError(error, returnError);
        }

        // Delete request
        if (isTrackedRequest) {
            this.currentRequests = this.currentRequests.filter(x => x != curId);
        }
    }



    // Create header
    private CreateHeader(authToken: string, acceptOnlyJson: boolean) : HttpHeaders {
        let header: { [name: string]: string } = {
            'Content-Type': 'application/json; charset=utf-8'
        };
        if (authToken) {
            header['Authorization'] = authToken;
        }
        if (acceptOnlyJson) {
            header['Accept'] = 'application/json';
        }
        return new HttpHeaders(header);
    }


    // Create parameter
    private CreateParameter(params: { [key: string]: string }): { [key: string]: string } {
        let tmp: { [key: string]: string } = {};
        for (let key in params) {
            if (params[key]) {
                tmp[key] = params[key];
            }
        }
        return tmp;
    }


    // Handle error
    private HandleError(error: any, returnError: boolean): any {
        if (!returnError) {
            console.error(error);

            // Unauthorized -> Back to login
            if (error.status == 401) {
                this.auth.Login();
            }
            return null;
        }
        return error;
    }
}