import { Workflow } from './workflow';
import { Material } from './material';

// Container for a material as well as the workflow
export class MaterialContainer {

    // ID of the object
    public id: string;

    // Workflow
    public workflow: Workflow = new Workflow();

    // Definition of the material
    public material: Material = new Material();
}
