import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { UserProfile } from 'app/model/userProfile';
import { WorkflowStepType } from 'app/model/workflowStepType';
import { UserService } from 'app/services/auth/user.service';
import { BackendRequestService } from 'app/services/request/backendRequest.service';

@Component({
    selector: 'app-admin-detail',
    templateUrl: './admin-detail.component.html',
    styleUrls: ['./admin-detail.component.css']
})
export class AdminDetailComponent {

    // Params
    private params = null;

    // User
    public user: UserProfile;


    // Constructor
    constructor(private route: ActivatedRoute, public userService: UserService, private backend: BackendRequestService) {
    }


    // On Init
    public async ngOnInit() {

        // Subscribe to changes
        this.route.paramMap.subscribe(params => {
            this.params = params;
            this.load();
        });
    }


    // Reload
    private async load() {
        this.user = null;
        if (this.params.get('id')) {
            this.user = await this.userService.GetUser(this.params.get('id'));
        }
    }


    // On change of a auth
    async onChange() {
        await this.backend.Post<any>('UserProfile/UpdateAuthorizations', this.user.authorization, { 'id': this.user.sub });
        await this.load();
    }
}

