import { Injectable } from '@angular/core';
import { ConfigurationService } from '../config/config.service';
import { AuthService } from './auth.service';
import { BackendRequestService } from '../request/backendRequest.service';
import { UserProfile } from 'app/model/userProfile';
import { WorkflowStepType } from 'app/model/workflowStepType';
import { PorscheTools } from 'app/common/PorscheToolsStore';
import { UserDto } from 'app/dto/UserDto';
import { BehaviorSubject, Observable } from 'rxjs';


// Service to provide user information (i.e. admins)
@Injectable({
    providedIn: 'root',
})
export class UserService {

    // Count of all users
    public count: number;

    // Already loaded users
    private users: { [id: string]: UserProfile } = {};

    // Workflow users
    public workflowUsers: UserProfile[];

    // Current user
    private _currentUser: UserProfile | undefined;
    get currentUser(): UserProfile | undefined {
        return this._currentUser;
    }

    // User change event
    private _changed: BehaviorSubject<void> = new BehaviorSubject<void>(null);
    get changed(): Observable<void> {
        return this._changed.asObservable();
    }

    // Constructor
    constructor(private backendRequest: BackendRequestService, private auth: AuthService) {
        this.auth.changed.subscribe(x => this.Load());
    }


    // Refresh the data from the auth server
    public async Load() {
        this.workflowUsers = [];
        this.users = {}
        if (this.auth.IsAuthenticated()) {
            await this.backendRequest.Post<void>('UserProfile/UpdateOwnUserProfile', '', {}, true);
            this._currentUser = await this.backendRequest.Get<UserProfile>('UserProfile/GetOwnUserProfile', {}, true);
            this.workflowUsers = await this.backendRequest.Get<UserProfile[]>('UserProfile/GetWorkflowUserProfiles', {}, true);
            this.count = await this.backendRequest.Get<number>('UserProfile/CountUserProfiles', {}, true);

            // Add users
            this.users[this._currentUser.sub] = this._currentUser;
            this.workflowUsers.forEach(user =>
                this.users[user.sub] = user
            );
            this._changed.next(null);
        }
        else {
            this._currentUser = undefined;
        }
    }

    // Get user
    public async GetUser(id: string): Promise<UserProfile> {
        if (id == undefined) {
            return undefined;
        }
        if (this.users[id]) {
            return this.users[id];
        }
        else {
            let user = await this.backendRequest.Get<UserProfile>('UserProfile/GetUserProfile', { 'id': id }, true);
            this.users[id] = user;
            return user;
        }
    }

    public async GetUserByIdAsync(id: string): Promise<UserProfile> {
        if (PorscheTools.isNullOrUndefined(id)) {
            return null;
        }

        const find = this.workflowUsers.find(x => x.id == id);
        if (!PorscheTools.isNullOrUndefined(find)) {
            return find;
        }
        else {
            let user = await this.backendRequest.Get<UserProfile>('UserProfile/GetUserProfileById', { 'id': id }, true);
            return user;
        }
    }

    public GetWorkflowUserById(id: string): UserProfile {
        if (PorscheTools.isNullOrUndefined(id)) {
            return null;
        }

        const find = this.workflowUsers.find(x => x.id == id);
        if (!PorscheTools.isNullOrUndefined(find)) {
            return find;
        }
        return null;
    }


    // Get users
    public async GetUsers(search: string, sortAttribute: string, sortAsc: boolean, skip: number, limit: number): Promise<UserProfile[]> {
        return this.backendRequest.Get<UserProfile[]>('UserProfile/GetUserProfiles',
            { 'search': search, 'sortAttribute': sortAttribute, 'sortAsc': sortAsc.toString(), 'skip': skip.toString(), 'limit': limit.toString() });
    }


    // Delete user
    public async DeleteUser(id: string) {
        await this.backendRequest.Delete('UserProfile/DeleteUserProfile', { 'id': id });
    }

    public async GetImportApproversAsync(Type: WorkflowStepType) {
        const o = new UserDto();
        o.userId = this._currentUser.id;
        o.WorkflowStepType = Type;

        const requestJson = PorscheTools.encodeHTML(JSON.stringify(o));
        let response: string = await this.backendRequest.Get<string>('UserProfile/GetImportApprovers',
            {
                "requestJson": requestJson
            }
        );
        const oResponse = JSON.parse(response) as UserDto;
        return oResponse;
    }
}