import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { UserProfile } from 'app/model/userProfile';
import { UserService } from 'app/services/auth/user.service';

@Component({
    selector: 'app-admin-overview',
    templateUrl: './admin-overview.component.html',
    styleUrls: ['./admin-overview.component.css']
})
export class AdminOverviewComponent {

    // Search
    searchForm: FormGroup;
    search() {
        this.doFilter();
    }

    // Paging
    itemsPerPage: number = 25;
    activePage: number = 1;
    paging(event: any) {
        this.activePage = event.detail.page;
        this.doFilter();
    }

    // Sorting and paging
    @ViewChild(MatSort) sort: MatSort;


    // Displayed columns
    public displayedColumns: string[] = [
        'actions',
        'corporation',
        'department',
        'family_name',
        'given_name',
        'email',
    ];

    // Data source
    public data: UserProfile[];


    // Constructor
    constructor(private formBuilder: FormBuilder, public userService: UserService, private dialog: MatDialog) {
    }


    // On init
    ngOnInit() {

        // Create form
        this.searchForm = this.formBuilder.group({
            search:  new FormControl('', [])
        });
    }


    // After init
    ngAfterViewInit() {
    
        // Subscribe sorting
        this.sort.sortChange.subscribe(x => this.doFilter());

        // Get data
        this.doFilter();
    }


    // Delete
    async delete(id: string) {
        let  dialogRef = this.dialog.open(AdminOverviewDeleteDialog);

        dialogRef.afterClosed().subscribe(async result => {
            if (result) {
                await this.userService.DeleteUser(id);
                await this.doFilter();
            }
        });
    }


    // Filter the data
    private async doFilter() {

        // Loop
        this.data = await this.userService.GetUsers(this.searchForm.get('search').value, this.sort ? this.sort.active : undefined, this.sort != undefined && this.sort.direction == 'asc', this.itemsPerPage * (this.activePage - 1), this.itemsPerPage);
    }
}


@Component({
    selector: 'app-admin-overview-delete-dialog',
    templateUrl: './admin-overview-delete-dialog.component.html'
})
export class AdminOverviewDeleteDialog {
    constructor(private dialogRef: MatDialogRef<AdminOverviewDeleteDialog>) {
    }

    click(data) {
        this.dialogRef.close(data);
    }
}