import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
    providedIn: "root",
  })
export class AuthGuard  {


    // Constructor
    constructor(private router: Router, private auth: AuthService) {
    }


    // Can activate
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        // Only by debugging
        // return true;
        if(!this.auth){
            return false;
        }

        if (this.auth.IsAuthenticated()) {
            return true;
        }
        //this.auth.Login();
        this.router.navigate(['/login']);
        return false;
    }
}