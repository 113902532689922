import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { MaterialService } from 'app/services/material.service';
import { WorkflowStepType } from 'app/model/workflowStepType';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, BehaviorSubject, Observable } from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { Material } from 'app/model/material';
import { MatButton } from '@angular/material/button';
import { ActivatedRoute } from '@angular/router';
import { MaterialDetailComponent } from '../material-detail/material-detail.component';

@Component({
  selector: 'app-material-inspect',
    templateUrl: './material-inspect.component.html',
    styleUrls: ['./material-inspect.component.css']
})
export class MaterialInspectComponent implements OnInit  {

    // Material detail components
    @ViewChild('materialForm1') materialForm1: MaterialDetailComponent;
    @ViewChild('materialForm2') materialForm2: MaterialDetailComponent;

    // Id of the material
    private id: string;
    private maxRevision: number;

    // Materials
    public material1: Material;
    public material2: Material;

    // Revision
    public revisions;


    // Constructor
    constructor(private route: ActivatedRoute, private material: MaterialService) {
    }


    // On Init
    public ngOnInit() {

        // Find the current workflow (or create a new one)
        this.route.paramMap.subscribe(async params => {

            // Reset
            this.material1 = undefined;
            this.material2 = undefined;
            this.id = params.get('materialId');
            this.maxRevision = +params.get('materialRevision');

            // Load material
            if (this.id && this.maxRevision != undefined) {
                this.revisions = Array(this.maxRevision + 1).fill(0).map((x, i) => i);
                this.material1 = await this.material.GetByIdAndRevision(this.id, this.maxRevision);
            }
        });
    }


    // On revision selection
    public async onMaterial1Select(event: any) {
        this.material1 = await this.material.GetByIdAndRevision(this.id, event.target.value);
        setTimeout(x => this.findChanges(), 200);
    }


    // On revision selection
    public async onMaterial2Select(event: any) {
        this.material2 = await this.material.GetByIdAndRevision(this.id, event.target.value);
        setTimeout(x => this.findChanges(), 200);
    }


    // Find changes between the two selected materials
    private findChanges() {
        if (this.material1 && this.material2 && this.materialForm1 && this.materialForm2) {
            for (let key in this.material1) {
                if (this.material1[key]?.toString() != this.material2[key]?.toString()) {
                    this.materialForm1.form.controls[key].setErrors({ 'incorrect': true });
                    this.materialForm2.form.controls[key].setErrors({ 'incorrect': true });
                }
            }
            this.materialForm1.form.markAllAsTouched();
            this.materialForm2.form.markAllAsTouched();
        }
    }
}
