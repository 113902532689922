<div id="container">

    <p-button-pure [routerLink]="['']" theme="dark" size="small">{{'common.app-name' | translate}}</p-button-pure>
    <div class="container-flex">
        <div *ngIf="auth.IsAuthenticated()" id="action-container">
            <p-button-pure *ngIf="user.currentUser?.authorization?.exportAuth" [matMenuTriggerFor]="exportMenu"
                icon="download" [hideLabel]="true" theme="dark"></p-button-pure>

            <p-button-pure *ngIf="user.currentUser?.authorization?.importInit" icon="upload" [hideLabel]="true"
                theme="dark" [routerLink]="['/import']"></p-button-pure>

            <mat-menu #exportMenu="matMenu">
                <button mat-menu-item (click)="onLatestRevisionExport()">{{'common.export.latestRevision' |
                    translate}}</button>
                <button mat-menu-item (click)="onAllRevisionsExport()">{{'common.export.allRevisions' |
                    translate}}</button>
                <button mat-menu-item (click)="onFilterExportAsync()">{{'common.export.filteredRevisions' |
                    translate}}</button>
            </mat-menu>

            <p-button-pure *ngIf="user.currentUser?.authorization?.workflowStepAuth[0]" icon="plus" [hideLabel]="true"
                theme="dark" [routerLink]="['/workflow/create']"></p-button-pure>

            <p-button-pure *ngIf="user.currentUser?.authorization?.importReview || user.currentUser?.authorization?.importConformity 
            || user.currentUser?.authorization?.importClosed" icon="wrench"
                [hideLabel]="lenghtOfImportsForCurrentUser == 0" theme="dark" badge="100" badgeClass="p-badge-warning"
                [routerLink]="['/import-inspect']">
                <span [matBadge]="lenghtOfImportsForCurrentUser" matBadgeColor="warn" matBadgeSize="small"
                    style="width: 48px;"></span>
            </p-button-pure>

            <p-button-pure
                *ngIf="user.currentUser?.authorization?.workflowStepAuth[0] || user.currentUser?.authorization?.workflowStepAuth[1]
            || user.currentUser?.authorization?.workflowStepAuth[2] || user.currentUser?.authorization?.workflowStepAuth[3]"
                icon="bell" [routerLink]="['/workflows']" [hideLabel]="workflow.data.length == 0" theme="dark">
                <span [matBadge]="workflow.data.length" matBadgeColor="warn" matBadgeSize="small"
                    style="width: 48px;"></span>
            </p-button-pure>
        </div>

        <div>
            <p-button-pure [matMenuTriggerFor]="settingsMenu" icon="configurate" [hideLabel]="true" theme="dark">
            </p-button-pure>
            <mat-menu #settingsMenu="matMenu">
                <button mat-menu-item [matMenuTriggerFor]="languageMenu">{{'common.language' | translate}}</button>
                <p-divider class="menu-item"></p-divider>
                <button mat-menu-item [matMenuTriggerFor]="infoMenu">Info</button>
                <p-divider class="menu-item"></p-divider>
                <button mat-menu-item (click)="onManageLicense()">{{'common.osoLicense' |
                    translate}}</button>
                <button mat-menu-item [routerLink]="['/userguide']">{{'common.userguide' |
                    translate}}</button>
            </mat-menu>
            <mat-menu #infoMenu="matMenu">
                <p-text class="menu-item menu-text-item menu-title" size="x-small" weight="bold">
                    {{'common.frontendVersion' | translate}}</p-text>
                <p-text class="menu-item menu-text-item menu-content" size="x-small">{{versionService.frontendVersion}}
                </p-text>
                <p-text class="menu-item menu-text-item menu-title" size="x-small" weight="bold">
                    {{'common.backendVersion' | translate}}</p-text>
                <p-text class="menu-item menu-text-item menu-content" size="x-small">{{versionService.backendVersion}}
                </p-text>
            </mat-menu>
            <mat-menu #languageMenu="matMenu">
                <button mat-menu-item (click)="translateService.use('de')">Deutsch</button>
                <button mat-menu-item (click)="translateService.use('en')">English</button>
            </mat-menu>

            <p-button-pure onclick="location.href='mailto:materialmaster@porsche.de';" icon="email" theme="dark">E-Mail-Support</p-button-pure>

            <p-button-pure *ngIf="auth.IsAuthenticated()" [matMenuTriggerFor]="userMenu" icon="user" [hideLabel]="true"
                theme="dark"></p-button-pure>

            <mat-menu #userMenu="matMenu">
                <button mat-menu-item *ngIf="user.currentUser?.authorization?.adminAuth"
                    [routerLink]="['/administration']">{{'common.administration' | translate}}</button>
                <p-divider class="menu-item"></p-divider>
                <div class="menu-text-item">
                    <p-text class="menu-item" size="x-small" weight="bold">{{user.currentUser?.name}}</p-text>
                    <p-text class="menu-item" size="x-small" weight="thin">{{user.currentUser?.sub}}</p-text>
                </div>
            </mat-menu>
            <p-button-pure (click)="logoutDialog()" icon="logout" [hideLabel]="true" theme="dark"></p-button-pure>
        </div>
    </div>
</div>