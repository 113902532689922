import { ImportFileState } from "../model/importFileState";
import { WorkflowStepType } from "../model/workflowStepType";

export class MaterialDto {

    public fileContent: string;
    public fileName: string;
    public resultCode: string;
    public result: string;
    public resultErrorMessage: string;
    public hasError: boolean;
    public language: string;
    public userId: string;
    public approverId: string;
    public loadOpenList: boolean;
    public importFileStateId: string;
    public importFileState: ImportFileState;
    public isAborted: boolean;
    public workflowStepType: WorkflowStepType;
    public importApprovalList: ImportFileState[];

}
