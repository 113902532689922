<mat-table [dataSource]="workflow.data">

    <!-- Actions -->
    <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>{{'common.actions' | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let container">
            <p-button variant="tertiary" hide-label="true" icon="edit" [routerLink]="['/workflow/process', container.id]"></p-button>
        </mat-cell>
    </ng-container>

    <!-- Material code -->
    <ng-container matColumnDef="materialCode">
        <mat-header-cell *matHeaderCellDef>{{'material.attribute.groupMaterialID' | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let container">{{container.material.groupMaterialID}}</mat-cell>
    </ng-container>

    <!-- Last editor -->
    <ng-container matColumnDef="creator">
        <mat-header-cell *matHeaderCellDef>{{'workflow.last-editor' | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let container">{{formatService.formatUserName(users[container.workflow.currentStep().creator])}}</mat-cell>
    </ng-container>

    <!-- Step type -->
    <ng-container matColumnDef="stepType">
        <mat-header-cell *matHeaderCellDef>{{'workflow.step' | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let container">{{'workflow.steps.' + container.workflow.currentStep().stepType | translate}}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>