import { formatDate } from '@angular/common';
import { Injectable, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AttributeDefinition, DisplayType } from '../model/materialDefinition';
import { UserProfile } from '../model/userProfile';
import { MaterialDefinitionService } from './materialDefinition.service';
import { PorscheTools } from '../common/PorscheToolsStore';

// Service to format data
@Injectable({
    providedIn: 'root',
})
export class FormatService {

    // Sorted attribute definition by technical name
    private _attributeDefinitionByTechnicalName: { [key: string]: AttributeDefinition } = {};

    // Constructor
    constructor(private translateService: TranslateService, private materialDefinitionService: MaterialDefinitionService) {
        for (let value of materialDefinitionService.materialDefinitions) {
            this._attributeDefinitionByTechnicalName[value.technicalName] = value;
        }
    }


    // Format data for edit views (from source)
    toEditView(value: any, technicalName: string): any {
        let attributeDefinition = this._attributeDefinitionByTechnicalName[technicalName];
        if (attributeDefinition?.displayType == DisplayType.Date) {
            return value ? formatDate(value, 'yyyy-MM-dd', 'en-US') : null;
        }
        return value;
    }


    // Format data from edit view (to source)
    fromEditView(value: any, technicalName: string): any {
        let attributeDefinition = this._attributeDefinitionByTechnicalName[technicalName];
        if (attributeDefinition?.displayType == DisplayType.Date) {
            return value ? value + 'T00:00:00Z' : null;
        }
        return value;
    }

    // Format data for readonly (from source)
    toReadView(value: any, technicalName: string): any {
        let attributeDefinition = this._attributeDefinitionByTechnicalName[technicalName];
        if (attributeDefinition?.displayType == DisplayType.Date) {
            return this.getFormatStringDateFromDateString(value, false , false);
            //return value ? formatDate(value, 'dd-MM-yyyy', 'en-US') : null;
        }
        if (attributeDefinition?.displayType == DisplayType.List) {
            return value ? value.join(', ') : null;
        }
        if (attributeDefinition?.displayType == DisplayType.Select) {
            return value ? this.translateService.instant('material.selection.' + attributeDefinition.technicalName + '.' + value) : null;
        }
        if (attributeDefinition?.displayType == DisplayType.Boolean) {
            return this.translateService.instant('material.boolean.' + attributeDefinition.technicalName + '.' + (value ? 'true' : 'false'));
        }
        return value;
    }

    // Format a search string to possible data search definitions
    fromSearchString(value: any, technicalName: any, splitValue: boolean = false): any[] {

        // Adjust value
        value = value.toLowerCase();
        value = splitValue ? value.split(' ') : [value];

        // Return
        let ret = [];

        // Format all values
        value.forEach(val => {
            let attributeDefinition = this._attributeDefinitionByTechnicalName[technicalName];
            if (attributeDefinition?.displayType == DisplayType.Select) {
                ret.push(val);
                // attributeDefinition.displayOptions['select'].forEach(option => {
                //     // if (this.translateService.instant('material.selection.' + attributeDefinition.technicalName + '.' + option).toLowerCase().includes(val)) {
                //     //     ret.push(option);
                //     // }
                //     if (this.translateService.instant('material.selection.' + attributeDefinition.technicalName + '.' + option).toLowerCase().includes(val)) {
                //         ret.push(val);
                //     }
                //     ret.push(val);
                // });
            }
            else if (attributeDefinition?.displayType == DisplayType.Boolean) {
                let tmp = [];
                if (this.translateService.instant('material.boolean.' + attributeDefinition.technicalName + '.true').toLowerCase().includes(val)) {
                    ret.push(true);
                }
                if (this.translateService.instant('material.boolean.' + attributeDefinition.technicalName + '.false').toLowerCase().includes(val)) {
                    ret.push(false);
                }
            }
            else {
                ret.push(val)
            }
        });
        return ret;
    }

    // Format user name
    formatUserName(user: UserProfile): string {
        return user?.corporation + ': ' + user?.name;
    }

    // Format table header cell
    formatTableHeader(value: any, technicalName: string): string {
        let attributeDefinition = this._attributeDefinitionByTechnicalName[technicalName];
        let output = "";
        if (attributeDefinition.isForTable == true) {
            output = 'material.attribute.' + technicalName;
        }
        return output;
    }

    
    //Format TypeScript Date String Value: 01.01.0001 00:00:00
    getFormatStringDateFromDateString(dateVal: string, withTime = true, withSeconds = true): string {
        if (PorscheTools.stringIsNullOrEmpty(dateVal)) { return ""; } else {

            const date = new Date(dateVal);
            return (
                ("0" + date.getDate()).slice(-2).toString() + "." + 
                ("0" + (date.getMonth() + 1)).slice(-2).toString() + "." + date.getFullYear().toString() +
                (withTime ? (" " + ("0" + date.getHours()).slice(-2).toString() + ":" + 
                ("0" + date.getMinutes()).slice(-2).toString() + (withSeconds ? ":" +
                ("0" + date.getSeconds()).slice(-2).toString() : "")) : "")
            );
        }
    }


}

