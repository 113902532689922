<div class="content">
    <div class="login-header">
        <span>
            Material Master > Login
        </span>
    </div>
    <div class="login-content">
        <mat-card appearance="outlined" class="login-card">
            <mat-card-title>
                <span>
                    Option 1 - <span style="font-weight: bold;">mit</span> PKI-Card
                </span>
            </mat-card-title>
            <mat-card-content class="option-buttons">
                <p-button [variant]="'tertiary'" (click)="loginGroupIdp()">VW CloudIDP</p-button>
            </mat-card-content>
        </mat-card>
        <mat-card appearance="outlined" class="login-card">
            <mat-card-title>
                <span>
                    Option 2 - <span style="font-weight: bold;">ohne</span> PKI-Card
                </span>
            </mat-card-title>
            <mat-card-content class="option-buttons">
                <p-button [variant]="'tertiary'" (click)="loginEntraId()">Porsche Entra ID</p-button>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="login-footer">

    </div>
</div>