import { Injectable } from "@angular/core";
import {
  UserManager,
  UserManagerSettings,
  User,
  Profile,
  WebStorageStateStore,
} from "oidc-client";
import { ConfigurationService } from "../config/config.service";
import { BehaviorSubject, Observable } from "rxjs";
import { PorscheTools } from "app/common/PorscheToolsStore";

export enum AuthType {
  EntraID = "entraId",
  GroupIDP = "groupIdp",
}
// Service to do the user authentification
@Injectable({
  providedIn: "root",
})
export class AuthService {
  // Manager
  private manager: UserManager;
  // User
  private user: User = null;
  private serverUrl: string;
  // Auth change event
  private _changed: BehaviorSubject<void> = new BehaviorSubject<void>(null);

  authType: AuthType;
  config: ConfigurationService;
  get changed(): Observable<void> {
    return this._changed.asObservable();
  }

  // Get auth header
  get AuthorizationHeader(): string {
    if (this.user == null) {
      return null;
    }
    return this.user.token_type + " " + this.user.access_token;
  }

  constructor(config?: ConfigurationService) {
    if (config) {
      this.config = config;
    }

    if (this.config) {
      this.InitAuth();
    }
  }

  InitAuth() {
    const authType = sessionStorage.getItem("authType");
    if (authType == "entraId") {
      this.authType = AuthType.EntraID;
    }
    if (authType == "groupIdp") {
      this.authType = AuthType.GroupIDP;
    }
    if (!this.config || !this.authType) {
      return;
    }

    if (this.authType == AuthType.EntraID) {
      const authData = this.config?.auth_EntraId;
      if (PorscheTools.isNullOrUndefined(authData)) {
        return;
      }

      if (this.manager) {
        return;
      }

      this.serverUrl = this.config.serverUrl;
      let defaultUri = this.serverUrl;

      let loginUri = defaultUri;
      let logoutUri = defaultUri;
      let silentUri = defaultUri;

      if (!PorscheTools.stringIsNullOrEmpty(authData.redirect_uri)) {
        loginUri = authData.redirect_uri;
      }

      loginUri = loginUri + "/auth-callback";
      logoutUri = logoutUri + "/logout-callback";
      silentUri = silentUri + "/silent-callback";

      // https://materialmaster-dev.porsche.com/auth-callback
      var userStore = new WebStorageStateStore({ store: localStorage });

      // Create manager (must match client settings in auth server)
      this.manager = new UserManager({
        authority: authData.authUrl,
        client_id: authData.clientId,
        //redirect_uri: config.serverUrl + '/auth-callback',
        redirect_uri: loginUri,
        //silent_redirect_uri: silentUri,
        //post_logout_redirect_uri: logoutUri,
        response_type: "id_token token",
        scope: authData.scope,
        extraQueryParams: {
          resource: authData.identifier,
        },
        loadUserInfo: false,
        userStore: userStore,
      } as UserManagerSettings);

      // Set the user
      this.manager.getUser().then((user) => {
        this.user = user;
        this._changed.next(null);
      });
    }
    if (this.authType == AuthType.GroupIDP) {
      const authData = this.config?.auth_groupidp;
      if (PorscheTools.isNullOrUndefined(authData)) {
        return;
      }

      if (this.manager) {
        return;
      }

      this.serverUrl = this.config.serverUrl;
      let defaultUri = this.serverUrl;

      let loginUri = defaultUri;
      let logoutUri = defaultUri;
      let silentUri = defaultUri;

      if (!PorscheTools.stringIsNullOrEmpty(authData.redirect_uri)) {
        loginUri = authData.redirect_uri;
      }

      loginUri = loginUri + "/auth-callback";
      logoutUri = logoutUri + "/login";
      silentUri = silentUri + "/silent-callback";

      // https://materialmaster-dev.porsche.com/auth-callback
      var userStore = new WebStorageStateStore({ store: localStorage });

      // Create manager (must match client settings in auth server)
      this.manager = new UserManager({
        authority: authData.authUrl,
        client_id: authData.clientId,
        // redirect_uri: config.serverUrl + '/auth-callback',
        redirect_uri: loginUri,
        silent_redirect_uri: silentUri,
        post_logout_redirect_uri: logoutUri,
        response_type: "id_token token",
        scope: authData.scope,

      } as UserManagerSettings);

      // Set the user
      this.manager.getUser().then((user) => {
        this.user = user;
        this._changed.next(null);
      });
    }
  }

  // Login
  Login() {
    sessionStorage.setItem("requestedUrl", "/");
    return this.manager.signinRedirect();
  }

  // Logout
async LogoutAsync(): Promise<void> {
  const idToken = this.user?.id_token;
  if (!idToken) {
      console.error("ID Token is not available");
      return;
  }
  this._changed.next(null);
  sessionStorage.removeItem("authType");

  try {
    await this.manager.clearStaleState();
    await this.manager.revokeAccessToken();
    await this.manager.removeUser();
    await this.manager.signoutRedirect();
  } catch (error) {
      console.warn('Error during local state cleanup:', error);
  }
}

  // Complete the authentification loop
  /*async CompleteAuthenticationAsync(): Promise<string> {
    this.user = await this.manager.signinRedirectCallback();
    this._changed.next(null);
    const requestedUrl = sessionStorage.getItem("requestedUrl");

    // token expiration monitoring
    this.monitorTokenExpiry();

    return requestedUrl;
  }*/
    async CompleteAuthenticationAsync(): Promise<string> {
      try {
          if (!this.manager) {
              console.error('UserManager is not initialized. Redirecting to login...');
              return '/login';
          }
  
          this.user = await this.manager.signinRedirectCallback();
          this._changed.next(null);
  
          const requestedUrl = sessionStorage.getItem('requestedUrl');
  
          // token expiration monitoring
          this.monitorTokenExpiry();
  
          return requestedUrl || '/';
      } catch (error) {
          console.error('Error during authentication callback:', error);
  
          return '/login';
      }
  }

  async CompleteLogoutAsync() {
    this.user = null;
    const response = await this.manager.signoutRedirectCallback();
    const requestedUrl = this.serverUrl;
    return requestedUrl;
  }

  async SilentSignInAuthenticationAsync() {
    return await this.manager.signinSilentCallback();
  }

  // Check if the user is authenticated
  IsAuthenticated(): boolean {
    return this.user != null && !this.user.expired;
  }

  // Get the userid
  GetUserId(): string | null {
    return this.IsAuthenticated() ? this.user.profile.sub : null;
  }

  // Get user profile
  GetUserProfile(): Profile | null {
    return this.IsAuthenticated() ? this.user.profile : null;
  }

  GetAccessToken() {
    return this.user ? this.user.access_token : null;
  }

  monitorTokenExpiry() {
    if (this.user && this.user.expires_at) {
        const expiryTime = this.user.expires_at * 1000;
        const timeLeft = expiryTime - Date.now();

        if (timeLeft > 0) {
            setTimeout(() => {
                console.log('Token expired, logging out...');
                this.LogoutAsync();
            }, timeLeft);
        }
    }
  }
}
