import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BackendRequestService } from "./request/backendRequest.service";


// Service to format data
@Injectable({
    providedIn: 'root',
})
export class VersionService {

    // Backend version
    private _backendVersion: string;
    get backendVersion() : string {
        return this._backendVersion;
    }
    
    
    // Frontend version
    private _frontendVersion: string;
    get frontendVersion() : string {
        return this._frontendVersion;
    }


    // Constructor
    constructor(private backendService: BackendRequestService, private httpClient: HttpClient) {
    }


    // Load
    async Load() {

        // Get frontend version
        const file = 'assets/version.config';
        this.httpClient.get(file,  {responseType: 'text', headers: new HttpHeaders({
            'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
            'Pragma': 'no-cache',
            'Expires': '0'
        })}).subscribe(data => {
            this._frontendVersion = data;
        });

        // Get backend version
        this._backendVersion = await this.backendService.Get<string>('Version/Get');
    }
}