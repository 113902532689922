import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth/auth.service';

@Component({
    selector: 'app-logout-callback',
    template: ''
})
export class AuthLogoutCallbackComponent implements OnInit {

    // Constructor
    constructor(private router: Router, private authService: AuthService) {
    }

    // OnInit
    async ngOnInit() {
        // Complete the authentification and then navigate back to the requested route
        const requestedUrl = await this.authService.CompleteLogoutAsync();
        this.router.navigateByUrl(requestedUrl);
    }
}
