<div class="content-div">
    <mat-card appearance="outlined" class="workflowoverview-card">
        <mat-card-title>Offene Aufgaben</mat-card-title>
        <mat-table class="scrollable-content" [dataSource]="openImportFileStateListForCurrentUser">
            <!-- SHow ImportFile -->
            <ng-container matColumnDef="importSelect">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <p-button variant="tertiary" hideLabel="true" icon="view"
                        (click)="selectImportFileStateAsync(element.id)">
                    </p-button>
                </mat-cell>
            </ng-container>

            <!-- Import-Code -->
            <ng-container matColumnDef="importName">
                <mat-header-cell *matHeaderCellDef>{{"import.filename" | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.filename}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="importCreator">
                <mat-header-cell *matHeaderCellDef>{{"import.creator" | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{getCreatorName(element)}}</mat-cell>
                <!-- <mat-cell *matCellDef="let element">123</mat-cell> -->
            </ng-container>

            <ng-container matColumnDef="importCreateDate">
                <mat-header-cell *matHeaderCellDef>{{"import.creationDate" | translate}}</mat-header-cell>
                <!-- <mat-cell *matCellDef="let element">{{element.creationDate| date: 'shortDate' : '' : translateService.currentLang}}</mat-cell> -->
                <mat-cell *matCellDef="let element">{{element.creationDate | date:'yyyy-MM-dd HH:mm:ss' }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="importState">
                <mat-header-cell *matHeaderCellDef>{{"import.filestate" | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{getImportStateValue(element) | translate}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="importStepType">
                <mat-header-cell *matHeaderCellDef>{{"workflow.step" | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{getImportStepTypeValue(element) | translate}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="nextApprover">
                <mat-header-cell *matHeaderCellDef>{{"workflow.approvers" | translate}}</mat-header-cell>
                <!-- <mat-cell *matCellDef="let element">{{getNextApproverName(element)}}</mat-cell> -->
                <mat-cell *matCellDef="let element"></mat-cell>
            </ng-container>

            <ng-container matColumnDef="importDate">
                <mat-header-cell *matHeaderCellDef>{{"import.importDate" | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.importDate | date:'yyyy-MM-dd HH:mm:ss' }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="importLastChangeDate">
                <mat-header-cell *matHeaderCellDef>{{"import.lastModificationDate" | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.lastModificationDate | date:'yyyy-MM-dd HH:mm:ss' }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>{{"common.actions" | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <div class="importActionmenu" *ngIf="showActions(element)">
                        <mat-form-field appearance="fill" *ngIf="showApprover(element)">
                            <mat-label>{{'workflow.approvers' | translate}}</mat-label>
                            <mat-select (selectionChange)="setApproverInput(element, $event)"
                                class="importActionmenuSelect" [formControl]="selected">
                                <mat-option *ngFor="let approver of getImportApprovers(element)" [value]="approver">
                                    {{approver.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <p-button-group class="buttonMenuOpenImports">
                            <p-button class="workflow-button" variant="secondary" icon="close"
                                (click)="denyImportAsync(element)">{{"workflow.deny" | translate}}</p-button>
                            <p-button class="workflow-button" variant="primary" hideLabel="true" icon="check"
                                [disabled]="getBtnCheckIsEnable(element)"
                                (click)="approveImportWorkflowStepAsync(element)">
                            </p-button>
                        </p-button-group>
                    </div>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumnsOpenTask"></mat-header-row>
            <mat-row class="importStateFileRow" [attr.importrowid]="getRowId(row)" selectedFlag="0"
                *matRowDef="let row; columns: displayedColumnsOpenTask;"></mat-row>
        </mat-table>
    </mat-card>
</div>

<div class="content-div">
    <mat-card appearance="outlined" class="workflowoverview-card">
        <mat-card-title>Meine Imports</mat-card-title>
        <mat-table class="scrollable-content" [dataSource]="myImportFileStateListForCurrentUser">
            <!-- SHow ImportFile -->
            <ng-container matColumnDef="importSelect">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <p-button variant="tertiary" hideLabel="true" icon="view"
                        (click)="selectImportFileStateAsync(element.id)">
                    </p-button>
                </mat-cell>
            </ng-container>

            <!-- Import-Code -->
            <ng-container matColumnDef="importName">
                <mat-header-cell *matHeaderCellDef>{{"import.filename" | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.filename}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="importCreator">
                <mat-header-cell *matHeaderCellDef>{{"import.creator" | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{getCreatorName(element)}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="importCreateDate">
                <mat-header-cell *matHeaderCellDef>{{"import.creationDate" | translate}}</mat-header-cell>
                <!-- <mat-cell *matCellDef="let element">{{element.creationDate| date: 'shortDate' : '' : translateService.currentLang}}</mat-cell> -->
                <mat-cell *matCellDef="let element">{{element.creationDate | date:'yyyy-MM-dd HH:mm:ss' }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="importState">
                <mat-header-cell *matHeaderCellDef>{{"import.filestate" | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{getImportStateValue(element) | translate}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="importStepType">
                <mat-header-cell *matHeaderCellDef>{{"workflow.step" | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{getImportStepTypeValue(element) | translate}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="nextApprover">
                <mat-header-cell *matHeaderCellDef>{{"workflow.approvers" | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{getNextApproverName(element)}}</mat-cell>
                <!-- <mat-cell *matCellDef="let element"></mat-cell> -->
            </ng-container>

            <ng-container matColumnDef="importDate">
                <mat-header-cell *matHeaderCellDef>{{"import.importDate" | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.importDate | date:'yyyy-MM-dd HH:mm:ss' }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="importLastChangeDate">
                <mat-header-cell *matHeaderCellDef>{{"import.lastModificationDate" | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.lastModificationDate | date:'yyyy-MM-dd HH:mm:ss' }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>{{"common.actions" | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <p-button-group>
                        <p-button class="workflow-button" variant="secondary" icon="close"
                            [disabled]="getBtnDenyIsVisible(element)" (click)="denyImportAsync(element)"
                            >{{"workflow.abort" | translate}}</p-button>
                    </p-button-group>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row class="importStateFileRow" [attr.importrowid]="getRowId(row)" selectedFlag="0"
                *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
    </mat-card>
</div>

<div class="content-div" *ngIf="currentImportFileStateId">
    <!-- <mat-card-title>Import-Table: {{currentImportFileStateId}}</mat-card-title> -->
    <app-import-material-temp-control class="table-container" #importMaterialsControl *ngIf="currentImportFileStateId"
        [importFileStateId]="currentImportFileStateId" enabledInputs="">
    </app-import-material-temp-control>
</div>