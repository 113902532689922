import { Injectable } from '@angular/core';
import { ConfigurationService } from '../config/config.service';
import { HttpRequestService } from './httpRequest.service';
import { AuthService } from '../auth/auth.service';


// Service to provide communication to backend
@Injectable({
    providedIn: 'root',
})
export class BackendRequestService {

    // Constructor
    constructor(private config: ConfigurationService, private httpRequest: HttpRequestService, private auth: AuthService) {
    }


    // POST from backend
    public async Post<T>(endpoint: string, body: any = '', params: { [key: string]: string } = null, isTrackedRequest: boolean = true): Promise<T> {

        // Adjust endpoint
        endpoint = this.config.backend.serverUrl + this.config.backend.apiPath + '/' + endpoint;

        // Return
        return this.httpRequest.Post<T>(endpoint, body, params, this.auth.AuthorizationHeader, false, true, isTrackedRequest, false);
    }


    // GET from backend
    public async Get<T>(endpoint: string, params: { [key: string]: string } = null, isTrackedRequest: boolean = true): Promise<T> {

        // Adjust endpoint
        endpoint = this.config.backend.serverUrl + this.config.backend.apiPath + '/' + endpoint;

        // Return
        return this.httpRequest.Get<T>(endpoint, params, this.auth.AuthorizationHeader, false, true, isTrackedRequest, false);
    }


    // DELETE from backend
    public async Delete(endpoint: string, params: { [key: string]: string } = null, isTrackedRequest: boolean = true) {

        // Adjust endpoint
        endpoint = this.config.backend.serverUrl + this.config.backend.apiPath + '/' + endpoint;

        // Return
        return this.httpRequest.Delete(endpoint, params, this.auth.AuthorizationHeader, false, true, isTrackedRequest, false);
    }
}