<div id="content">
    <p-grid>
        <p-grid-item size="6">
            <p-select-wrapper [label]="'material.attribute.revision' | translate">
                <select [value]="material1?.revision" (change)="onMaterial1Select($event)">
                    <option *ngFor="let i of revisions" [value]="i">{{i}}</option>
                </select>
            </p-select-wrapper>

            <app-material-detail #materialForm1 *ngIf="material1" [material]="material1" enabledInputs="" [showErrors]="false"></app-material-detail>
        </p-grid-item>

        <p-grid-item size="6">
            <p-select-wrapper [label]="'material.attribute.revision' | translate">
                <select [value]="material2?.revision" (change)="onMaterial2Select($event)">
                    <option [value]="null"></option>
                    <option *ngFor="let i of revisions" [value]="i">{{i}}</option>
                </select>
            </p-select-wrapper>

            <app-material-detail #materialForm2 *ngIf="material2" [material]="material2" enabledInputs="" [showErrors]="false"></app-material-detail>
        </p-grid-item>
    </p-grid>
</div>