import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { PorscheTools } from "app/common/PorscheToolsStore";
import { ImportFileState } from "app/model/importFileState";
import { ImportFileStateType } from "app/model/importFileStatetype";
import { Material } from "app/model/material";
import { UserProfile } from "app/model/userProfile";
import { UserService } from "app/services/auth/user.service";
import { MaterialService } from "app/services/material.service";
import { WorkflowService } from "app/services/workflow.service";

import { KeyValueDict } from "../../common/KeyValueDict";
import { WorkflowStepType } from "../../model/workflowStepType";
import * as $ from "jquery";
import { TranslateService } from "@ngx-translate/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";



@Component({
  selector: "app-import-inspect",
  templateUrl: "./import-inspect.component.html",
  styleUrls: ["./import-inspect.component.css"]
})
export class ImportInspectComponent implements OnInit {

  importTemp: ImportFileState;
  materials: Material[];
  form: FormGroup;
  isDisabled: boolean;
  openImportFileStateListForCurrentUser: ImportFileState[];
  myImportFileStateListForCurrentUser: ImportFileState[];

  approverDict: KeyValueDict;
  importCreatorNameDict: KeyValueDict;
  nextApproverDict: KeyValueDict;
  selectedApproverDict: KeyValueDict;

  currentImportFileStateId: string;

  public displayedColumnsOpenTask: string[] = [
    "importSelect",
    "importName",
    "importCreator",
    "importCreateDate",
    "importState",
    "importStepType",
    "importDate",
    "importLastChangeDate",
    "actions"
  ];
  public displayedColumns: string[] = [
    "importSelect",
    "importName",
    "importCreator",
    "importCreateDate",
    "importState",
    "importStepType",
    "nextApprover",
    "importDate",
    "importLastChangeDate",
    "actions"
  ];

  constructor(public materialService: MaterialService, public userService: UserService, public workflowService: WorkflowService, public translateService: TranslateService, private dialog: MatDialog) {
    this.isDisabled = true;
    this.currentImportFileStateId = null;
    this.approverDict = new KeyValueDict();
    this.importCreatorNameDict = new KeyValueDict();
    this.selectedApproverDict = new KeyValueDict();
    this.nextApproverDict = new KeyValueDict();

    this.loadOpenImportApprovalListAsync();
    this.loadMyImportApprovalListAsync();
  }


  ngOnInit(): void {
  }

  async loadOpenImportApprovalListAsync(): Promise<ImportFileState[]> {
    const response = await this.materialService.GetImportApprovalListForCurrentUserAsync(true);
    this.openImportFileStateListForCurrentUser = response?.importApprovalList;

    this.openImportFileStateListForCurrentUser.map(async x => {
      let importId = x.id;
      let importApproverList = await this.userService.GetImportApproversAsync(x.stepType);
      this.approverDict.add(importId, importApproverList?.userProfiles);

      if (!this.importCreatorNameDict.has(x.materialTemp?.creator)) {
        const user = await this.userService.GetUserByIdAsync(x.materialTemp?.creator);
        if (!PorscheTools.isNullOrUndefined(user)) {
          this.importCreatorNameDict.add(x.materialTemp?.creator, user.name);
        }
        else {
          this.importCreatorNameDict.add(x.materialTemp?.creator, "");
        }
      }
    });

    return this.openImportFileStateListForCurrentUser;
  }

  async loadMyImportApprovalListAsync(): Promise<ImportFileState[]> {
    const response = await this.materialService.GetImportApprovalListForCurrentUserAsync(false);
    this.myImportFileStateListForCurrentUser = response?.importApprovalList;

    this.myImportFileStateListForCurrentUser.map(async x => {
      //Ersteller Namen werden geladen in Dict
      if (!this.importCreatorNameDict.has(x.materialTemp?.creator)) {
        const user = await this.userService.GetUserByIdAsync(x.materialTemp?.creator);
        if (!PorscheTools.isNullOrUndefined(user)) {
          this.importCreatorNameDict.add(x.materialTemp?.creator, user.name);
        }
        else {
          this.importCreatorNameDict.add(x.materialTemp?.creator, "");
        }
      }
      //Genehmiger Namen werden ins Dict geladen
      if (x.stepType == WorkflowStepType.Review) {
        let user = await this.userService.GetUserByIdAsync(x.materialTemp?.approverReview);
        this.nextApproverDict.add(x.materialTemp?.approverReview, user.name);
      }
      if (x.stepType == WorkflowStepType.Conformity) {
        let user = await this.userService.GetUserByIdAsync(x.materialTemp?.approverConformity);
        this.nextApproverDict.add(x.materialTemp?.approverConformity, user.name);
      }
      if (x.stepType == WorkflowStepType.Closed) {
        let user = await this.userService.GetUserByIdAsync(x.materialTemp?.approverClosed);
        this.nextApproverDict.add(x.materialTemp?.approverClosed, user.name);
      }
    });

    return this.myImportFileStateListForCurrentUser;
  }

  getRowId(row: any) {
    return row.id;
  }

  //changing Backgroundrowcolor
  async selectImportFileStateAsync(id: string) {
    // async selectImportFileStateAsync(id: string, isMy: boolean) {
    const lastId = this.currentImportFileStateId;
    let attrImportId = "importrowid";
    if (this.currentImportFileStateId != id) {
      this.currentImportFileStateId = id;
      // if(isMy){
      //   attrImportId = "importrowidmy";
      // }
      // else{
      //   attrImportId ="importrowidopen"
      // }
      let e = $("[" + attrImportId + "='" + id + "']");
      if (e.length > 0) {
        const eRow = $(e[0]);
        eRow.attr("selectedFlag", "1");
      }

      if (!PorscheTools.isNullOrUndefined(lastId)) {
        e = $("[" + attrImportId + "='" + lastId + "']");
        if (e.length > 0) {
          const eRow = $(e[0]);
          eRow.attr("selectedFlag", "0");
        }
      }
    }
  }

  async denyImportAsync(element: ImportFileState) {
    let dialogRef = this.dialog.open(DenyImportDialog);
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (!PorscheTools.isNullOrUndefined(element)) {
          const response = await this.materialService.UpdateImportWorkflowAsync(null, element.id, true);
          await this.loadOpenImportApprovalListAsync();
          await this.loadMyImportApprovalListAsync();
        }
      }
    });

  }

  async approveImportWorkflowStepAsync(element: ImportFileState) {

    if (!PorscheTools.isNullOrUndefined(element)) {
      if (this.selectedApproverDict.has(element.id)) {
        const approverId = this.selectedApproverDict.get(element.id);
        if (!PorscheTools.stringIsNullOrEmpty(approverId)) {
          const response = await this.materialService.UpdateImportWorkflowAsync(approverId, element.id, false);
          await this.loadOpenImportApprovalListAsync();
          await this.loadMyImportApprovalListAsync();
        }
      }
      else if (element.stepType == WorkflowStepType.Closed) {
        const response = await this.materialService.UpdateImportWorkflowAsync(null, element.id, false);
        await this.loadOpenImportApprovalListAsync();
        await this.loadMyImportApprovalListAsync();
      }
    }
  }

  getImportApprovers(element: ImportFileState): UserProfile[] {
    if (!PorscheTools.isNullOrUndefined(element)) {

      if (this.approverDict.has(element.id)) {
        const list = this.approverDict.get(element.id) as UserProfile[];
        return list;
      }
    }
    return [];
  }

  setApproverInput(element: ImportFileState, event: any) {
    if (!PorscheTools.isNullOrUndefined(element) && !PorscheTools.isNullOrUndefined(event)) {
      const approverInput = event.value as UserProfile;
      if (this.selectedApproverDict.has(element.id)) {
        this.selectedApproverDict.set(element.id, approverInput.id);
      }
      else {
        this.selectedApproverDict.add(element.id, approverInput.id);
      }
    }
  }

  getCreatorName(element: ImportFileState): string {
    let name = "";
    if (!PorscheTools.isNullOrUndefined(element)) {
      if (this.importCreatorNameDict.has(element.materialTemp.creator)) {
        const name = this.importCreatorNameDict.get(element.materialTemp.creator);
        return name;
      }
    }

    return name;
  }

  getNextApproverName(element: ImportFileState): string {
    let name = "";
    if (!PorscheTools.isNullOrUndefined(element)) {
      switch (element.stepType) {
        case 0:
          if (this.nextApproverDict.has(element.materialTemp.creator)) {
            const name = this.nextApproverDict.get(element.materialTemp.creator);
            return name;
          }
        case 1:
          if (this.nextApproverDict.has(element.materialTemp.approverReview)) {
            const name = this.nextApproverDict.get(element.materialTemp.approverReview);
            return name;
          }
        case 2:
          if (this.nextApproverDict.has(element.materialTemp.approverConformity)) {
            const name = this.nextApproverDict.get(element.materialTemp.approverConformity);
            return name;
          }
        case 3:
          if (this.nextApproverDict.has(element.materialTemp.approverClosed)) {
            const name = this.nextApproverDict.get(element.materialTemp.approverClosed);
            return name;
          }
        default:
          return name;
      }
    }
  }

  getFormatDate(element: ImportFileState): string {
    let date = "";
    if (!PorscheTools.isNullOrUndefined(element)) {

    }
    return date;
  }

  getBtnCheckIsEnable(element: ImportFileState) {
    if (!PorscheTools.isNullOrUndefined(element)) {
      if (this.selectedApproverDict.has(element.id)) {
        const approverId = this.selectedApproverDict.get(element.id);
        return PorscheTools.isNullOrUndefined(approverId);
      }
      else if (element.stepType == WorkflowStepType.Closed) {
        return false;
      }
    }

    return true;
  }

  getBtnDenyIsVisible(element: ImportFileState) {
    if (!PorscheTools.isNullOrUndefined(element)) {
      if ((element.stepType != WorkflowStepType.Closed) && (element.state != ImportFileStateType.abort)) {
        return false;
      }
    }
    return true;
  }

  getImportStateValue(element: ImportFileState) {
    if (!PorscheTools.isNullOrUndefined(element)) {

      const value = element.state.toString();
      return "import.state." + value;
    }

    return true;
  }

  getImportStepTypeValue(element: ImportFileState) {
    if (!PorscheTools.isNullOrUndefined(element)) {

      const value = element.stepType.toString();
      return "workflow.steps." + value;
    }

    return true;
  }

  showActions(element: ImportFileState) {
    if (!PorscheTools.isNullOrUndefined(element)) {
      return (element.state != ImportFileStateType.imported)
        && (element.state != ImportFileStateType.importedWithErrors)
        && (element.state != ImportFileStateType.abort);
    }

    return true;
  }

  showApprover(element: ImportFileState) {
    if (!PorscheTools.isNullOrUndefined(element)) {
      return (element.stepType != WorkflowStepType.Closed);
    }

    return true;
  }
}

@Component({
  selector: 'deny-import-dialog',
  templateUrl: './deny-import-dialog.component.html',
  styleUrls: ["./import-inspect.component.css"]
})
export class DenyImportDialog {
  constructor(private dialogRef: MatDialogRef<DenyImportDialog>) {
  }

  click(data) {
    this.dialogRef.close(data);
  }
}
