import { KeyValueData } from "./KeyValueData";

/**
 * Data Class for KeyValueDict
 */
export class KeyValueDict {
    /**
     * data
     */
    public dictdata: KeyValueData[];

    constructor() {
        if (this.dictdata == null) {
            this.dictdata = new Array<KeyValueData>();
        }
    }

    /**
     * check if exists
     */
    public has(key: string): boolean {
        if (this.dictdata != null) {
            const findData = this.dictdata.find(x => x.key == key);

            return (findData != null);
        }

        return false;
    }

    /**
     * return the data
     */
    public get(key: string): any {
        if (this.dictdata != null) {
            const findData = this.dictdata.find(x => x.key == key);

            return findData.value;
        }

        return null;
    }

    /**
     * add the data
     */
    public add(key: string, value: any) {
        if (this.dictdata != null) {
            if (!this.has(key)) {
                const newData = new KeyValueData();
                newData.key = key;
                newData.value = value;
                this.dictdata.push(newData);
            }
        }
    }

    /**
     * set the value for key
     */
    public set(key: string, value: any) {
        if (this.dictdata != null) {
            if (this.has(key)) {
                let i = -1;
                let iFound = -1;
                this.dictdata.map(x => {
                    i++;
                    if (x.key == key) {
                        iFound = i;

                        return;
                    }
                });
                if (iFound >= 0) {
                    this.dictdata[iFound].value = value;
                }
            } else {
                this.add(key, value);
            }
        }
    }

    /**
     * remove
     */
    public remove(key: string) {
        if (this.dictdata != null) {
            if (this.has(key)) {
                let i = -1;
                let iFound = -1;
                this.dictdata.map(x => {
                    i++;
                    if (x.key == key) {
                        iFound = i;

                        return;
                    }
                });
                if (iFound >= 0) {
                    this.dictdata.splice(iFound, 1);
                }
            }
        }
    }

    public clear() {
        if (this.dictdata != null) {
            this.dictdata = null;
        }
    }
}
