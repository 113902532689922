import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Material } from 'app/model/material';
import { FormGroup, FormControl, Validators, AbstractControl, Form, FormBuilder, ValidatorFn } from '@angular/forms';
import { alphanumericValidator } from 'app/helpers/alphanumericValidator';
import { AttributeDefinition, DisplayType } from 'app/model/materialDefinition';
import { FormatService } from 'app/services/format.service';
import { MaterialDefinitionService } from 'app/services/materialDefinition.service';
//import * as $ from "jquery";
import { KeyValueDict } from '../../common/KeyValueDict';
import { PorscheTools } from '../../common/PorscheToolsStore';

@Component({
    selector: 'app-material-detail',
    templateUrl: './material-detail.component.html',
    styleUrls: ['./material-detail.component.css']
})
export class MaterialDetailComponent implements OnInit, OnDestroy {

    // Display type
    DisplayType = DisplayType;


    // Track value changes
    private _trackValueChanges: boolean = true;


    // Material
    private _material: Material;
    @Input() set material(val: Material) {
        this._material = val;
        if (this._material && this.form) {
            for (let key in this._material) {
                let control = this.form.get(key);
                if (control) {
                    control.setValue(this.formatService.toEditView(this._material[key], key));
                }
            }
        }
    }
    get material(): Material {
        return this._material;
    }


    // Disabled inputs
    private _enabledInputs: string[];
    @Input() set enabledInputs(val: string[]) {
        // Remove defaultGeneralComment
        this._enabledInputs = val ? val.filter(x => x != 'defaultGeneralComment') : val;
        if (this._enabledInputs && this.form) {
            for (let key in this.form.controls) {
                val.indexOf(key) == -1 ? this.form.controls[key].disable() : this.form.controls[key].enable();
            }
        }
    }
    get enabledInputs(): string[] {
        return this._enabledInputs;
    }


    // Error
    @Input() showErrors: boolean = true;


    // Form
    form: FormGroup;


    // Is the form valid?
    get valid(): boolean {
        return this.form.valid || this.form.status == 'DISABLED';
    }


    // Constructor
    constructor(private formBuilder: FormBuilder, private formatService: FormatService, public materialDefinitionService: MaterialDefinitionService) {
    }


    private dictSelectOptions: KeyValueDict;
    private displayTypeSelect: AttributeDefinition[];

    ngOnDestroy(): void {
        this.dictSelectOptions.clear();
        this.dictSelectOptions = null;

        this.displayTypeSelect = [];
        this.displayTypeSelect = null;
    }

    // On init
    ngOnInit(): void {

        // Create form
        this.form = this.formBuilder.group({});
        for (let attributeDefinition of this.materialDefinitionService.materialDefinitions) {
            let initValue = attributeDefinition.displayType != DisplayType.List ? null : [];
            let validators = [];
            for (let val of attributeDefinition.validation) {
                validators.push(val.validator);
            }
            this.form.addControl(attributeDefinition.technicalName, new FormControl(initValue, validators));
        }

        // Add conditional validators
        for (let attributeDefinition of this.materialDefinitionService.materialDefinitions) {
            if (attributeDefinition.displayOptions && attributeDefinition.displayOptions['conditions']) {
                for (let condition of attributeDefinition.displayOptions['conditions']) {
                    this.form.get(condition['attribute']).valueChanges.subscribe(val => {
                        this.updateConditionalValidators(attributeDefinition);
                    });
                }
            }
        }

        // Set values
        if (this.material) {
            for (let key in this._material) {
                let control = this.form.get(key);
                if (control) {
                    control.setValue(this.formatService.toEditView(this._material[key], key));
                }
            }
        }

        // Enable / disable
        if (this.enabledInputs) {
            for (let key in this.form.controls) {
                this.enabledInputs.indexOf(key) == -1 ? this.form.controls[key].disable() : this.form.controls[key].enable();
            }
        }


        this.dictSelectOptions = new KeyValueDict();
        this.displayTypeSelect = [] as AttributeDefinition[];
        this.displayTypeSelect = this.materialDefinitionService.materialDefinitions
            .filter(x => x.displayType === DisplayType.Select)
            .map(x => x);

        this.displayTypeSelect.map(x => {
            if (!this.dictSelectOptions.has(x.technicalName)) {

                const list = x.displayOptions["select"] as string[];
                this.dictSelectOptions.add(x.technicalName, list);
            }
        });

    }


    // Is visible
    isVisible(attributeDefinition: AttributeDefinition): boolean {
        //alert(attributeDefinition.technicalName);
        if (attributeDefinition.displayOptions && attributeDefinition.displayOptions['conditions']) {
            for (let condition of attributeDefinition.displayOptions['conditions']) {
                if (this.form.get(condition['attribute']).value === condition['value']) {
                    return true;
                }
            }
            return false;
        }
        return true;
    }

    // Get error state
    errorState(attributeDefinition: AttributeDefinition): boolean {

        // Check form
        let tmp = this.form.get(attributeDefinition.technicalName);
        if (tmp.untouched) {
            return false;
        }
        if (tmp.errors) {
            return true;
        }
        return false;
    }


    // Get error message 
    errorMessage(attributeDefinition: AttributeDefinition): string {
        // No message when we show no error
        if (!this.showErrors) {
            return undefined;
        }

        // Get error message
        let tmp = this.form.get(attributeDefinition.technicalName).errors;
        if (tmp) {
            for (let val of attributeDefinition.validation) {
                if (tmp[val.errorAttribute]) {
                    let newMessage = val.errorMessage;
                    if (!PorscheTools.isNullOrUndefined(val.amount)) {
                        if (val.amount > 0) {
                            newMessage = newMessage.replace("{{amount}}", val.amount.toString());
                        }
                    }

                    return newMessage;
                }
            }
        }
        return undefined;
    }


    // Update material with current input
    updateMaterial() {
        for (let key in this.form.value) {
            this.material[key] = this.formatService.fromEditView(this.form.value[key], key);
        }
    }


    // Set conditional validation
    private updateConditionalValidators(attributeDefinition: AttributeDefinition) {

        // Get form value
        let control = this.form.get(attributeDefinition.technicalName);

        // Set validators
        if (this.isVisible(attributeDefinition)) {
            let validators = [];
            for (let val of attributeDefinition.validation) {
                validators.push(val.validator);
            }
            control.setValidators(validators);
        }
        else {
            control.setValidators(null);
            this._trackValueChanges = false;
            let initVal = attributeDefinition.displayType != DisplayType.List ? null : [];
            control.setValue(initVal);
            if (this.material) {
                this.material[attributeDefinition.technicalName] = initVal;
            }
            this._trackValueChanges = true;
        }
        control.updateValueAndValidity();
    }


    // List item
    addListItem(key: string, input: any) {
        let value = input.value;

        // Adjust
        if (this.form.get(key).value == null) {
            this.form.get(key).setValue([]);
        }

        // Add
        if ((value || '').trim()) {
            this.form.get(key).value.push(value);
            this.form.get(key).updateValueAndValidity();
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
    }


    // List item
    removeListItem(key: string, value: any) {
        let index = this.form.get(key).value.indexOf(value);
        this.form.get(key).value.splice(index, 1);
        this.form.get(key).updateValueAndValidity()
    }

    onSelectChanged(attributeDefinition: AttributeDefinition, target: any) {
        this.clearChildValues(attributeDefinition);
    }

    clearChildValues(attributeDefinition: AttributeDefinition) {
        if (PorscheTools.isNullOrUndefined(attributeDefinition)) {
            return;
        }

        const techName = attributeDefinition.technicalName;
        const list = this.materialDefinitionService.materialDefinitions.filter(x => !PorscheTools.stringIsNullOrEmpty(x.parentDefinitionName));
        const childElements = list.filter(x => x.parentDefinitionName === techName);

        if (childElements.length > 0) {
            childElements.map(x => {
                const e = this.form.get(x.technicalName);
                e.setValue(null);

                this.clearChildValues(x);

                e.updateValueAndValidity();
            });
        }
    }

    getSelectOptions(attributeDefinition: AttributeDefinition) {

        let list = [] as string[];
        //return list;  

        if (PorscheTools.isNullOrUndefined(attributeDefinition)) {
            return list;
        }

        if (PorscheTools.isNullOrUndefined(this.dictSelectOptions)) {
            return list;
        }

        const techName = attributeDefinition.technicalName;
        const e = this.form.get(techName);

        if (PorscheTools.isNullOrUndefined(e)) {
            return list;
        }


        if (this.dictSelectOptions.has(techName)) {

            let options = this.dictSelectOptions.get(techName);
            if (!PorscheTools.isNullOrUndefined(options)) {


                const optionList = options as string[];

                optionList.map(x => {

                    if (!PorscheTools.stringIsNullOrEmpty(attributeDefinition.parentDefinitionName)) {
                        const eParent = this.form.get(attributeDefinition.parentDefinitionName);
                        if (!PorscheTools.isNullOrUndefined(eParent)) {

                            const parentVal = PorscheTools.StringVal(eParent.value);
                            if (!PorscheTools.stringIsNullOrEmpty(parentVal)) {
                                if (x.toLocaleUpperCase().trim().startsWith(parentVal.toLocaleUpperCase().trim() + "_")) {
                                    list.push(x);
                                }
                            }
                        }
                    }
                    else {
                        list.push(x);
                    }
                });
            }
        }

        e.updateValueAndValidity();
        return list;
    }

    getRequired(attributeDefinition: AttributeDefinition): boolean {
        let required = attributeDefinition.required;

        if (!PorscheTools.isNullOrUndefined(attributeDefinition.validation)) {
            const validator = attributeDefinition.validation.find(x =>
                !PorscheTools.isNullOrUndefined(x.validator)
                && x.validator === Validators.required
                && !PorscheTools.isNullOrUndefined(x.requiredAttributeDefinitions));
            if (!PorscheTools.isNullOrUndefined(validator)) {
                validator.requiredAttributeDefinitions.map(x => {
                    if (x !== attributeDefinition.technicalName) {
                        const control = this.form.get(x);
                        if (!PorscheTools.isNullOrUndefined(control.value)) {
                            required = false;
                        }
                    }
                });
            }
        }
        return required;
    }
}

