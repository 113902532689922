import { Component, OnInit, ViewChild } from '@angular/core';
import { WorkflowService } from 'app/services/workflow.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { MaterialContainer } from 'app/model/materialContainer';
import { MaterialService } from 'app/services/material.service';
import * as cloneDeep from 'lodash/cloneDeep';
import { WorkflowStep } from 'app/model/workflowStep';
import { Material } from 'app/model/material';
import { WorkflowStepType } from 'app/model/workflowStepType';
import { UserService } from 'app/services/auth/user.service';
import { Profile } from 'oidc-client';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MaterialDetailComponent } from '../material-detail/material-detail.component';
import { TranslateService } from '@ngx-translate/core';
import { UserProfile } from 'app/model/userProfile';
import { FormatService } from 'app/services/format.service';
import { PorscheTools } from 'app/common/PorscheToolsStore';


@Component({
    selector: 'app-workflow-detail',
    templateUrl: './workflow-detail.component.html',
    styleUrls: ['./workflow-detail.component.css']
})
export class WorkflowDetailComponent implements OnInit {

    // Material detail component
    @ViewChild('materialForm') materialForm: MaterialDetailComponent;

    // Params
    private params = null;

    // Current workflow
    public materialContainer: MaterialContainer;

    public userService: UserService;

    // Current material
    public material: Material;

    // Current workflow step
    public workflowStep: WorkflowStep;

    // Workflow write permissions
    public workflowWritePermissions: string[] = [];

    

    // Approvers (possible approvers)
    public  get  approvers(): UserProfile[] {
        try {
            var approvers  = this.user.workflowUsers.filter(userProfile => userProfile.authorization.workflowStepAuth[this.workflowStep.stepType]); 
            approvers = approvers.filter(x => x.id != this.user.currentUser.id);
            return approvers;
        }
        catch {
            return null;
        }
    }


    // Form
    form : FormGroup;


    // Is the form valid?
    public get showDeny(): boolean {
        return this.workflowStep && this.workflowStep.stepType != WorkflowStepType.Init;
    }


    // Constructor
    constructor(private translateService: TranslateService, private router: Router, private route: ActivatedRoute, public workflow: WorkflowService, private materialService: MaterialService, private user: UserService, public formatService: FormatService) {
    }

    // On Init
    public async ngOnInit() {

        // Create form
        this.form = new FormGroup({
            currentStepTranslation: new FormControl({value: null, disabled: true}, []),
            lastEditor: new FormControl({value: null, disabled: true}, []),
            approverInput: new FormControl(null, []),
            approvers: new FormControl([], [Validators.required])
        });

        // Subscribe to changes
        this.route.paramMap.subscribe(params => {
            this.params = params;
            this.load();
        });
        this.workflow.changed.subscribe(x => this.load());

        // Subscribe approvers
        this.form.get('approverInput').valueChanges.subscribe(value => this.addApprover(value));
    }


    // Reload
    private async load() {

        let tmp: MaterialContainer;

        // Load existing workflow
        if (this.params.get('id')) {
            tmp = this.workflow.data.find(x => x.id == this.params.get('id'));
        }
        // Create a new workflow (either clone or completely new)
        else {
            tmp = new MaterialContainer();
            if (this.params.get('materialId') && this.params.get('materialRevision')) {
                tmp.material = cloneDeep(await this.materialService.GetByIdAndRevision(this.params.get('materialId'), +this.params.get('materialRevision')));
                if (tmp.material) {
                    tmp.material.revision += 1;
                }
            }
        }

        // If anything is undefined, we have a new workflow
        if (!tmp || !tmp.material) {
            this.materialContainer = new MaterialContainer();
        }
        else {
            this.materialContainer = tmp;
        }

        // Copy the material and create a workflow step
        this.material = cloneDeep(this.materialContainer.material);
        this.workflowStep = {
            entitledApprovers: [],
            stepType: this.materialContainer.workflow.currentStep() != null ? this.materialContainer.workflow.currentStep().stepType + 1 : WorkflowStepType.Init,
            material: this.material
        } as WorkflowStep;

        // Get the write permissions
        this.workflowWritePermissions = this.workflow.workflowWritePermissions[this.workflowStep.stepType];

        // Populate the form
        this.form.get('currentStepTranslation').setValue(this.translateService.instant('workflow.steps.' + this.materialContainer?.workflow?.currentStep()?.stepType));
        let lastEditor = await this.user.GetUser(this.materialContainer?.workflow?.currentStep()?.creator);
        this.form.get('lastEditor').setValue(this.formatService.formatUserName(lastEditor))
    }


    // Add approver
    addApprover(approver: Profile) {
        if (approver != null) {
            // Check if value is existing
            if (this.form.get('approvers').value.findIndex(x => x == approver) == -1) {
                this.form.get('approvers').value.push(approver);
                this.form.get('approvers').updateValueAndValidity();
            }
           
            this.form.get('approverInput').patchValue(null);
        }
    }


    // Remove approver
    removeApprover(approver: Profile) {
        let index = this.form.get('approvers').value.indexOf(approver);
        this.form.get('approvers').value.splice(index, 1);
        this.form.get('approvers').updateValueAndValidity()
    }


    // Submit button is pressed
    public async onSubmit() {

        // Check if valid 
        if (this.form.valid && this.materialForm.valid) {

            // Update material
            this.materialForm.updateMaterial();

            // Update
            this.workflowStep.entitledApprovers = this.form.get('approvers').value.map(x => x.sub);

            // Update
            await this.workflow.Update(this.materialContainer, this.workflowStep);
            this.redirect();
        }
        else {
            this.form.markAllAsTouched();
            this.materialForm.form.markAllAsTouched();
        }
    }


    // Deny button is pressed
    public async onDeny() {

        // Check if valid 
        if (this.materialForm.valid) {

            // Update material
            this.materialForm.updateMaterial();

            // Update
            await this.workflow.Previous(this.materialContainer, this.material);
            this.redirect();
        }
        else {
            this.form.markAsUntouched();
            this.materialForm.form.markAllAsTouched();
        }
    }


    // Abort button is pressed
    public async onAbort() {

        // Navigate
        this.redirect();
    }


    // Redirect the user back to the route he came from
    private redirect() {

        // Navigate
        if (this.materialContainer.id) {
            this.router.navigate(['/workflows']);
        }
        else {
            this.router.navigate(['']);
        }
    }
}

