import { Component, OnInit } from "@angular/core";
import { AuthService, AuthType } from "app/services/auth/auth.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  constructor(
    private auth: AuthService,
  ) {}

  ngOnInit(): void {}

  public async loginEntraId() {
    sessionStorage.setItem('authType', 'entraId');
    this.auth.InitAuth();
    this.auth.Login();
  }

  public async loginGroupIdp() {
    sessionStorage.setItem('authType', 'groupIdp');
    this.auth.InitAuth();
    this.auth.Login();
  }
}
