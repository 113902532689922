import { Input } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { alphanumericValidator } from '../helpers/alphanumericValidator';


export class AttributeDefinition {
    technicalName: string;
    required: boolean;
    parentDefinitionName: string;
    validation: ValidationDefinition[];
    displayType: DisplayType;
    displayOptions: {};
    layout: LayoutDefinition;
    isForTable: boolean;
    showInDetailView: boolean;
}


export class ValidationDefinition {
    validator: ValidatorFn;
    errorAttribute: string;
    errorMessage: string;
    amount?: number;
    requiredAttributeDefinitions?: string[];
}


export class LayoutDefinition {
    group: number;
    rowIndex: number;
    colIndex: number;
    colSpan: number;
    offset?: number = undefined;
}


export enum DisplayType {
    Input,
    Select,
    Date,
    List,
    Boolean
}


export const MaterialDefinition: AttributeDefinition[] = [
    {
        technicalName: 'id',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.required,
            errorAttribute: 'required',
            errorMessage: 'errors.invalid-input'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 0,
            rowIndex: 0,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'revision',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.required,
            errorAttribute: 'required',
            errorMessage: 'errors.invalid-input'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 0,
            rowIndex: 0,
            colIndex: 4,
            colSpan: 1
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'lastModificationDate',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.required,
            errorAttribute: 'required',
            errorMessage: 'errors.invalid-input'
        }],
        displayType: DisplayType.Date,
        displayOptions: undefined,
        layout: {
            group: 0,
            rowIndex: 0,
            colIndex: 4,
            colSpan: 3,
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'drawingEntryWithoutID',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.required,
            errorAttribute: 'required',
            errorMessage: 'errors.invalid-input'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 0,
            rowIndex: 1,
            colIndex: 9,
            colSpan: 4,
        },
        isForTable: true,
        showInDetailView: false,
    },
    
    {
        technicalName: 'drawingEntryWithIDshort',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.required,
            errorAttribute: 'required',
            errorMessage: 'errors.invalid-input'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 0,
            rowIndex: 1,
            colIndex: 9,
            colSpan: 4,
        },
        isForTable: true,
        showInDetailView: false,
    },
    {
        technicalName: 'drawingEntryWithID',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.required,
            errorAttribute: 'required',
            errorMessage: 'errors.invalid-input'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 0,
            rowIndex: 1,
            colIndex: 9,
            colSpan: 4,
        },
        isForTable: true,
        showInDetailView: false,
    },
    {
        technicalName: 'groupMaterialID',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.required,
            errorAttribute: 'required',
            errorMessage: 'errors.invalid-input'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 0,
            rowIndex: 1,
            colIndex: 4,
            colSpan: 4
        },
        isForTable: false,
        showInDetailView: true,
    },
    {
        technicalName: 'dataSetType',
        parentDefinitionName: null,
        required: true,
        validation: [{
            amount: 0,
            validator: Validators.required,
            errorAttribute: 'required',
            errorMessage: 'errors.invalid-input'
        }],
        displayType: DisplayType.Select,
        displayOptions: {
            select: [
                'm',
                'c',
                't',
                's',
                'f',
                'p',
                'e',
                'q'
            ]
        },
        layout: {
            group: 0,
            rowIndex: 3,
            colIndex: 0,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'description',
        parentDefinitionName: null,
        required: false,
        validation: [],
        displayType: DisplayType.Select,
        displayOptions: {
            select: [
                'steelandIronMaterial',
                'sheetSteelGeneral',
                'steelCaseHardeningHeatTreatable',
                'screwMaterials',
                'steelHighAlloyCorrosionResistant',
                'elastomers',
                'thermoplastics',
                'duromere',
                'wood',
                'glass',
                'fuels',
                'textileLeather',
                'lacquers',
                'mineralsCeramics',
                'ktl',
                'effectVarnish',
                'zincNickelElectroplated',
                'sheetSteelThicknessTolerance',
                'roundTolerance'
            ]
        },
        layout: {
            group: 0,
            rowIndex: 3,
            colIndex: 2,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'description2',
        parentDefinitionName: 'description',
        required: false,
        validation: [],
        displayType: DisplayType.Select,
        displayOptions: {
            select: [
                'steelandIronMaterial_sheetSteelColdForming',
                'steelandIronMaterial_sheetSteelWarmForming',
            ]
        },
        layout: {
            group: 0,
            rowIndex: 3,
            colIndex: 5,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'description3',
        parentDefinitionName: 'description2',
        required: false,
        validation: [],
        displayType: DisplayType.Select,
        displayOptions: {
            select: [
                'steelandIronMaterial_sheetSteelColdForming_SoftSteel',
                'steelandIronMaterial_sheetSteelColdForming_LowAlloyedSteel',
                'steelandIronMaterial_sheetSteelColdForming_HighSolidSteel',
                'steelandIronMaterial_sheetSteelColdForming_BakeHardeningSteel',
                'steelandIronMaterial_sheetSteelColdForming_DualPhaseSteel',
                'steelandIronMaterial_sheetSteelColdForming_DualPhaseHighDuctility',
                'steelandIronMaterial_sheetSteelColdForming_TripSteel',
                'steelandIronMaterial_sheetSteelColdForming_ComplexPhaseSteel',
                'steelandIronMaterial_sheetSteelColdForming_ComplexPhaseSteelHighDuctility',
                'steelandIronMaterial_sheetSteelColdForming_FerriticBainiticSteel',
                'steelandIronMaterial_sheetSteelColdForming_MartensicSteel',
                'steelandIronMaterial_sheetSteelWarmForming_ManganBorSteel1500',
                'steelandIronMaterial_sheetSteelWarmForming_ManganBorSteel1900',
                'steelandIronMaterial_sheetSteelWarmForming_InductiveHardenedPipeSteel',
                'steelandIronMaterial_sheetSteelWarmForming_MicroAlloyedSteel'
            ]
        },
        layout: {
            group: 0,
            rowIndex: 3,
            colIndex: 6,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'regulationNumber',
        parentDefinitionName: null,
        required: true,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(16),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }, {
            validator: Validators.required,
            errorAttribute: 'required',
            errorMessage: 'errors.invalid-input'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 0,
            rowIndex: 5,
            colIndex: 2,
            colSpan: 2
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'legalAuthority',
        parentDefinitionName: null,
        required: true,
        validation: [{
            amount: 0,
            validator: Validators.required,
            errorAttribute: 'required',
            errorMessage: 'errors.invalid-input'
        }],
        displayType: DisplayType.Select,
        displayOptions: {
            select: [
                'vw',
                'din',
                'en',
                'iso',
                'astm',
                'sae',
                'jis',
                'nbr'
            ]
        },
        layout: {
            group: 0,
            rowIndex: 4,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'issueDate',
        parentDefinitionName: null,
        required: true,
        validation: [{
            amount: 0,
            validator: Validators.required,
            errorAttribute: 'required',
            errorMessage: 'errors.invalid-input'
        }],
        displayType: DisplayType.Date,
        displayOptions: undefined,
        layout: {
            group: 0,
            rowIndex: 4,
            colIndex: 4,
            colSpan: 2
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'shortName',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 0,
            rowIndex: 5,
            colIndex: 4,
            colSpan: 5
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'features',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(100),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(['.', '/', '_', '(', ')', '[', ']']),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 0,
            rowIndex: 5,
            colIndex: 9,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'additionalInformation',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(1000),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(['.', '/', '_', '(', ')', '[', ']']),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 0,
            rowIndex: 6,
            colIndex: 0,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'materialNumber',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(20),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(['.']),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 0,
            rowIndex: 6,
            colIndex: 3,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'vdaMainGroup',
        parentDefinitionName: null,
        required: true,
        validation: [{
            amount: 0,
            validator: Validators.required,
            errorAttribute: 'required',
            errorMessage: 'errors.invalid-input'
        }],
        displayType: DisplayType.Select,
        displayOptions: {
            select: [
                'vda_231_106_1997_01_01_1',
                'vda_231_106_1997_01_01_2',
                'vda_231_106_1997_01_01_3',
                'vda_231_106_1997_01_01_4',
                'vda_231_106_1997_01_01_5',
                'vda_231_106_1997_01_01_6',
                'vda_231_106_1997_01_01_7',
                'vda_231_106_1997_01_01_8',
                'vda_231_106_1997_01_01_9',
            ]
        },
        layout: {
            group: 1,
            rowIndex: 0,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'vdaSubGroup',
        parentDefinitionName: null,
        required: true,
        validation: [{
            amount: 0,
            validator: Validators.required,
            errorAttribute: 'required',
            errorMessage: 'errors.invalid-input'
        }],
        displayType: DisplayType.Select,
        displayOptions: {
            select: [
                'vda_231_106_1997_01_01_1_1',
                'vda_231_106_1997_01_01_1_1_1',
                'vda_231_106_1997_01_01_1_1_2',
                'vda_231_106_1997_01_01_1_2',
                'vda_231_106_1997_01_01_1_2_1',
                'vda_231_106_1997_01_01_1_2_2',
                'vda_231_106_1997_01_01_1_2_3',
                'vda_231_106_1997_01_01_2_1',
                'vda_231_106_1997_01_01_2_1_1',
                'vda_231_106_1997_01_01_2_1_2',
                'vda_231_106_1997_01_01_2_2',
                'vda_231_106_1997_01_01_2_2_1',
                'vda_231_106_1997_01_01_2_2_2',
                'vda_231_106_1997_01_01_2_3',
                'vda_231_106_1997_01_01_3_1',
                'vda_231_106_1997_01_01_3_2',
                'vda_231_106_1997_01_01_3_3',
                'vda_231_106_1997_01_01_3_4',
                'vda_231_106_1997_01_01_3_5',
                'vda_231_106_1997_01_01_4_1',
                'vda_231_106_1997_01_01_4_2',
                'vda_231_106_1997_01_01_5_1_a',
                'vda_231_106_1997_01_01_5_1_b',
                'vda_231_106_1997_01_01_5_2',
                'vda_231_106_1997_01_01_5_3',
                'vda_231_106_1997_01_01_5_4',
                'vda_231_106_1997_01_01_5_4_1',
                'vda_231_106_1997_01_01_5_4_2',
                'vda_231_106_1997_01_01_5_4_3',
                'vda_231_106_1997_01_01_5_5',
                'vda_231_106_1997_01_01_5_5_1',
                'vda_231_106_1997_01_01_5_5_2',
                'vda_231_106_1997_01_01_6_1',
                'vda_231_106_1997_01_01_6_2',
                'vda_231_106_1997_01_01_6_3',
                'vda_231_106_1997_01_01_7_1',
                'vda_231_106_1997_01_01_7_2',
                'vda_231_106_1997_01_01_7_3',
                'vda_231_106_1997_01_01_8_1',
                'vda_231_106_1997_01_01_8_2',
                'vda_231_106_1997_01_01_9_1',
                'vda_231_106_1997_01_01_9_2',
                'vda_231_106_1997_01_01_9_3',
                'vda_231_106_1997_01_01_9_4',
                'vda_231_106_1997_01_01_9_5',
                'vda_231_106_1997_01_01_9_6',
                'vda_231_106_1997_01_01_9_7',
                'vda_231_106_1997_01_01_9_8',

            ]
        },
        layout: {
            group: 1,
            rowIndex: 0,
            colIndex: 4,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'vdaIdentification',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(100),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(['<', '>']),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 1,
            rowIndex: 0,
            colIndex: 8,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'name',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(30),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 1,
            rowIndex: 1,
            colIndex: 0,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'alias',
        parentDefinitionName: null,
        required: false,
        validation: [],
        displayType: DisplayType.List,
        displayOptions: undefined,
        layout: {
            group: 0,
            rowIndex: 6,
            colIndex: 8,
            colSpan: 2
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'idmsNodeId',
        parentDefinitionName: null,
        required: false,
        validation: [
            //     {
            //     amount: 0,
            //     validator: alphanumericValidator(['.', '/']),
            //     errorAttribute: 'alphanumeric',
            //     errorMessage: 'errors.alphanumeric'
            // }
        ],
        displayType: DisplayType.List,
        displayOptions: undefined,
        layout: {
            group: 6,
            rowIndex: 0,
            colIndex: 0,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'missId',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: alphanumericValidator(['.', '/']),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 6,
            rowIndex: 0,
            colIndex: 4,
            colSpan: 2
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'language',
        parentDefinitionName: null,
        required: true,
        validation: [{
            amount: 0,
            validator: Validators.required,
            errorAttribute: 'required',
            errorMessage: 'errors.invalid-input'
        }],
        displayType: DisplayType.Select,
        displayOptions: {
            select: [
                'de',
                'en',
                'es'
            ]
        },
        layout: {
            group: 1,
            rowIndex: 1,
            colIndex: 3,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'rawMaterial',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(200),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 1,
            rowIndex: 3,
            colIndex: 0,
            colSpan: 2
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'semiFinishedProduct',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(200),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 1,
            rowIndex: 3,
            colIndex: 2,
            colSpan: 2
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'treatment',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(200),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 1,
            rowIndex: 3,
            colIndex: 4,
            colSpan: 2
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'manufacturingProcess',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(200),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 1,
            rowIndex: 3,
            colIndex: 6,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'finish',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(200),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 1,
            rowIndex: 3,
            colIndex: 9,
            colSpan: 2
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'vdaVersionIdentifier',
        parentDefinitionName: null,
        required: true,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(20),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            amount: 0,
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }, {
            amount: 0,
            validator: Validators.required,
            errorAttribute: 'required',
            errorMessage: 'errors.invalid-input'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 1,
            rowIndex: 1,
            colIndex: 7,
            colSpan: 5
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'isDensity',
        parentDefinitionName: null,
        required: true,
        validation: [{
            amount: 0,
            validator: Validators.required,
            errorAttribute: 'required',
            errorMessage: 'errors.invalid-input'
        }],
        displayType: DisplayType.Boolean,
        displayOptions: {
            displayName: undefined
        },
        layout: {
            group: 2,
            rowIndex: 0,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'minimumDensity',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: {
            conditions: [{
                attribute: 'isDensity',
                value: true
            }]
        },
        layout: {
            group: 2,
            rowIndex: 1,
            colIndex: 0,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'typicalDensity',
        parentDefinitionName: null,
        required: true,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }, {
            amount: 0,
            validator: Validators.required,
            errorAttribute: 'required',
            errorMessage: 'errors.invalid-input'
        }],
        displayType: DisplayType.Input,
        displayOptions: {
            conditions: [{
                attribute: 'isDensity',
                value: true
            }]
        },
        layout: {
            group: 2,
            rowIndex: 1,
            colIndex: 4,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'maximumDensity',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: {
            conditions: [{
                attribute: 'isDensity',
                value: true
            }]
        },
        layout: {
            group: 2,
            rowIndex: 1,
            colIndex: 8,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'minimumWeightPerUnitArea',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: {
            conditions: [{
                attribute: 'isDensity',
                value: false
            }]
        },
        layout: {
            group: 2,
            rowIndex: 2,
            colIndex: 0,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'typicalWeightPerUnitArea',
        parentDefinitionName: null,
        required: true,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }, {
            amount: 0,
            validator: Validators.required,
            errorAttribute: 'required',
            errorMessage: 'errors.invalid-input'
        }],
        displayType: DisplayType.Input,
        displayOptions: {
            conditions: [{
                attribute: 'isDensity',
                value: false
            }]
        },
        layout: {
            group: 2,
            rowIndex: 2,
            colIndex: 4,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'maximumWeightPerUnitArea',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: {
            conditions: [{
                attribute: 'isDensity',
                value: false
            }]
        },
        layout: {
            group: 2,
            rowIndex: 2,
            colIndex: 8,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'minimumElasticLimit',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 3,
            rowIndex: 0,
            colIndex: 0,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'typicalElasticLimit',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 3,
            rowIndex: 0,
            colIndex: 4,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'maximumElasticLimit',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 3,
            rowIndex: 0,
            colIndex: 8,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'minimumTensileStrength',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 3,
            rowIndex: 1,
            colIndex: 0,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'typicalTensileStrength',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 3,
            rowIndex: 1,
            colIndex: 4,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'maximumTensileStrength',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 3,
            rowIndex: 1,
            colIndex: 8,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'minimumElongation',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 3,
            rowIndex: 2,
            colIndex: 0,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'typicalElongation',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 3,
            rowIndex: 2,
            colIndex: 3,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'maximumElongation',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 3,
            rowIndex: 2,
            colIndex: 6,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'specimenType',
        parentDefinitionName: null,
        required: false,
        validation: [],
        displayType: DisplayType.Select,
        displayOptions: {
            select: [
                'iso_6892_1_type_1',
                'iso_6892_1_type_2',
                'iso_6892_1_type_3',
                'iso_6892_1_pipe',
                'din_50125',
                'proportional',
                'specialShape'
            ]
        },
        layout: {
            group: 3,
            rowIndex: 2,
            colIndex: 9,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'typicalEModulus',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 3,
            rowIndex: 3,
            colIndex: 0,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'typicalPoissonRatio',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 2,
            rowIndex: 3,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'typicalThermalExpansion',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 2,
            rowIndex: 3,
            colIndex: 5,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'isDefaultGeneralComment',
        parentDefinitionName: null,
        required: true,
        validation: [{
            amount: 0,
            validator: Validators.required,
            errorAttribute: 'required',
            errorMessage: 'errors.invalid-input'
        }],
        displayType: DisplayType.Boolean,
        displayOptions: undefined,
        layout: {
            group: 0,
            rowIndex: 2,
            colIndex: 0,
            colSpan: 3
        },
        isForTable: false,
        showInDetailView: true,
    },
    {
        technicalName: 'defaultGeneralComment',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.required,
            errorAttribute: 'required',
            errorMessage: 'errors.invalid-input'
        }],
        displayType: DisplayType.Input,
        displayOptions: {
            conditions: [{
                attribute: 'isDefaultGeneralComment',
                value: true
            }]
        },
        layout: {
            group: 0,
            rowIndex: 2,
            colIndex: 3,
            colSpan: 9
        },
        isForTable: false,
        showInDetailView: true,
    },
    {
        technicalName: 'customGeneralComment',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(250),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(['.', '/', '_', '(', ')', '[', ']']),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }, {
            validator: Validators.required,
            errorAttribute: 'required',
            errorMessage: 'errors.invalid-input'
        }],
        displayType: DisplayType.Input,
        displayOptions: {
            conditions: [{
                attribute: 'isDefaultGeneralComment',
                value: false
            }]
        },
        layout: {
            group: 0,
            rowIndex: 2,
            colIndex: 3,
            colSpan: 9
        },
        isForTable: false,
        showInDetailView: true,
    },
    {
        technicalName: 'hasUseRestriction',
        parentDefinitionName: null,
        required: true,
        validation: [{
            amount: 0,
            validator: Validators.required,
            errorAttribute: 'required',
            errorMessage: 'errors.invalid-input'
        }],
        displayType: DisplayType.Boolean,
        displayOptions: undefined,
        layout: {
            group: 5,
            rowIndex: 7,
            colIndex: 0,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'useRestrictionDetail',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(1000),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(['.', '/', '_', '(', ')', '[', ']']),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }, {
            validator: Validators.required,
            errorAttribute: 'required',
            errorMessage: 'errors.invalid-input'
        }],
        displayType: DisplayType.Input,
        displayOptions: {
            conditions: [{
                attribute: 'hasUseRestriction',
                value: true
            }]
        },
        layout: {
            group: 5,
            rowIndex: 7,
            colIndex: 0,
            colSpan: 10
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'useStatusCadSystemsGroup',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.required,
            errorAttribute: 'required',
            errorMessage: 'errors.invalid-input'
        }],
        displayType: DisplayType.Select,
        displayOptions: {
            select: [
                'inDevelopment',
                'forRelease',
                'released',
                'notForRedesign',
                'blocked',
                'notForRedesignFrom',
                'blockedFrom'
            ]
        },
        layout: {
            group: 6,
            rowIndex: 1,
            colIndex: 0,
            colSpan: 6
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'useStatusCadSystemsGroupDate',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.required,
            errorAttribute: 'required',
            errorMessage: 'errors.invalid-input'
        }],
        displayType: DisplayType.Date,
        displayOptions: {
            conditions: [{
                attribute: 'useStatusCadSystemsGroup',
                value: 'notForRedesignFrom'
            }, {
                attribute: 'useStatusCadSystemsGroup',
                value: 'blockedFrom'
            }]
        },
        layout: {
            group: 6,
            rowIndex: 1,
            colIndex: 7,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'useStatusCadSystemsAudi',
        parentDefinitionName: null,
        required: false,
        validation: [],
        displayType: DisplayType.Select,
        displayOptions: {
            select: [
                'inDevelopment',
                'forRelease',
                'released',
                'notForRedesign',
                'blocked',
                'notForRedesignFrom',
                'blockedFrom'
            ]
        },
        layout: {
            group: 5,
            rowIndex: 0,
            colIndex: 0,
            colSpan: 6
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'useStatusCadSystemsAudiDate',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.required,
            errorAttribute: 'required',
            errorMessage: 'errors.invalid-input'
        }],
        displayType: DisplayType.Date,
        displayOptions: {
            conditions: [{
                attribute: 'useStatusCadSystemsAudi',
                value: 'notForRedesignFrom'
            }, {
                attribute: 'useStatusCadSystemsAudi',
                value: 'blockedFrom'
            }]
        },
        layout: {
            group: 5,
            rowIndex: 0,
            colIndex: 7,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'useStatusCadSystemsPorsche',
        parentDefinitionName: null,
        required: false,
        validation: [],
        displayType: DisplayType.Select,
        displayOptions: {
            select: [
                'inDevelopment',
                'forRelease',
                'released',
                'notForRedesign',
                'blocked',
                'notForRedesignFrom',
                'blockedFrom'
            ]
        },
        layout: {
            group: 5,
            rowIndex: 1,
            colIndex: 0,
            colSpan: 6
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'useStatusCadSystemsPorscheDate',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.required,
            errorAttribute: 'required',
            errorMessage: 'errors.invalid-input'
        }],
        displayType: DisplayType.Date,
        displayOptions: {
            conditions: [{
                attribute: 'useStatusCadSystemsPorsche',
                value: 'notForRedesignFrom'
            }, {
                attribute: 'useStatusCadSystemsPorsche',
                value: 'blockedFrom'
            }]
        },
        layout: {
            group: 5,
            rowIndex: 1,
            colIndex: 7,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'useStatusCadSystemsMAN',
        parentDefinitionName: null,
        required: false,
        validation: [],
        displayType: DisplayType.Select,
        displayOptions: {
            select: [
                'inDevelopment',
                'forRelease',
                'released',
                'notForRedesign',
                'blocked',
                'notForRedesignFrom',
                'blockedFrom'
            ]
        },
        layout: {
            group: 5,
            rowIndex: 2,
            colIndex: 0,
            colSpan: 6
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'useStatusCadSystemsMANDate',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.required,
            errorAttribute: 'required',
            errorMessage: 'errors.invalid-input'
        }],
        displayType: DisplayType.Date,
        displayOptions: {
            conditions: [{
                attribute: 'useStatusCadSystemsMAN',
                value: 'notForRedesignFrom'
            }, {
                attribute: 'useStatusCadSystemsMAN',
                value: 'blockedFrom'
            }]
        },
        layout: {
            group: 5,
            rowIndex: 2,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'useStatusCadSystemsScania',
        parentDefinitionName: null,
        required: false,
        validation: [],
        displayType: DisplayType.Select,
        displayOptions: {
            select: [
                'inDevelopment',
                'forRelease',
                'released',
                'notForRedesign',
                'blocked',
                'notForRedesignFrom',
                'blockedFrom'
            ]
        },
        layout: {
            group: 5,
            rowIndex: 3,
            colIndex: 0,
            colSpan: 6
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'useStatusCadSystemsScaniaDate',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.required,
            errorAttribute: 'required',
            errorMessage: 'errors.invalid-input'
        }],
        displayType: DisplayType.Date,
        displayOptions: {
            conditions: [{
                attribute: 'useStatusCadSystemsScania',
                value: 'notForRedesignFrom'
            }, {
                attribute: 'useStatusCadSystemsScania',
                value: 'blockedFrom'
            }]
        },
        layout: {
            group: 5,
            rowIndex: 3,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'useStatusCadSystemsVW',
        parentDefinitionName: null,
        required: false,
        validation: [],
        displayType: DisplayType.Select,
        displayOptions: {
            select: [
                'inDevelopment',
                'forRelease',
                'released',
                'notForRedesign',
                'blocked',
                'notForRedesignFrom',
                'blockedFrom'
            ]
        },
        layout: {
            group: 5,
            rowIndex: 4,
            colIndex: 0,
            colSpan: 6
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'useStatusCadSystemsVWDate',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.required,
            errorAttribute: 'required',
            errorMessage: 'errors.invalid-input'
        }],
        displayType: DisplayType.Date,
        displayOptions: {
            conditions: [{
                attribute: 'useStatusCadSystemsVW',
                value: 'notForRedesignFrom'
            }, {
                attribute: 'useStatusCadSystemsVW',
                value: 'blockedFrom'
            }]
        },
        layout: {
            group: 5,
            rowIndex: 4,
            colIndex: 7,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'useStatusCadSystemsMarkets',
        parentDefinitionName: null,
        required: false,
        validation: [],
        displayType: DisplayType.Select,
        displayOptions: {
            select: [
                'ww',
                'eu',
                'nar',
                'sar',
                'ap',
                'cn',
                'in',
                'rus',
                'za'
            ]
        },
        layout: {
            group: 5,
            rowIndex: 6,
            colIndex: 0,
            colSpan: 6
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'standardStatus',
        parentDefinitionName: null,
        required: true,
        validation: [{
            amount: 0,
            validator: Validators.required,
            errorAttribute: 'required',
            errorMessage: 'errors.invalid-input'
        }],
        displayType: DisplayType.Select,
        displayOptions: {
            select: [
                'valid',
                'withdrawn'
            ]
        },
        layout: {
            group: 1,
            rowIndex: 2,
            colIndex: 4,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'nolisClassification',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(5),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 1,
            rowIndex: 2,
            colIndex: 0,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'inflammability',
        parentDefinitionName: null,
        required: false,
        validation: [],
        displayType: DisplayType.Boolean,
        displayOptions: undefined,
        layout: {
            group: 4,
            rowIndex: 0,
            colIndex: 0,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'idPredecessor',
        parentDefinitionName: null,
        required: false,
        validation: [
            //    {
            //     amount: 0,
            //     validator: Validators.maxLength(20),
            //     errorAttribute: 'maxlength',
            //     errorMessage: 'errors.max-length'
            // }, {
            //     validator: alphanumericValidator([';']),
            //     errorAttribute: 'alphanumeric',
            //     errorMessage: 'errors.alphanumeric'}
            //TODO: requirements
        ],
        displayType: DisplayType.List,
        displayOptions: undefined,
        layout: {
            group: 0,
            rowIndex: 1,
            colIndex: 0,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'idSuccessor',
        parentDefinitionName: null,
        required: false,
        validation: [
            //    {
            //     amount: 0,
            //     validator: Validators.maxLength(20),
            //     errorAttribute: 'maxlength',
            //     errorMessage: 'errors.max-length'
            // }, {
            //     validator: alphanumericValidator([';']),
            //     errorAttribute: 'alphanumeric',
            //     errorMessage: 'errors.alphanumeric'}
            //TODO: requirements
        ],
        displayType: DisplayType.List,
        displayOptions: undefined,
        layout: {
            group: 0,
            rowIndex: 1,
            colIndex: 0,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'previousPagMaterialId',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(12),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        },
        {
            amount: 0,
            validator: alphanumericValidator(['.']),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 1,
            rowIndex: 2,
            colIndex: 8,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'applicableNorms',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 0,
            rowIndex: 3,
            colIndex: 9,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'regulationType',
        parentDefinitionName: null,
        required: true,
        validation: [{
            amount: 0,
            validator: Validators.required,
            errorAttribute: 'required',
            errorMessage: 'errors.invalid-input'
        }],
        displayType: DisplayType.Select,
        displayOptions: {
            select: [
                'din',
                'din_en',
                'din_en_iso',
                'iso',
                'en',
                'jis',
                'astm',
                'sae',
                'nbr',
                'vw',
                'tl',
                'pv',
                'manufacturerDataSheet',
                'testDataSheet',
                'literatureDataSheet',
                'other'
            ]
        },
        layout: {
            group: 0,
            rowIndex: 5,
            colIndex: 0,
            colSpan: 2
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'minimumSizeSemiFinishedProduct',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 0,
            rowIndex: 7,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'maximumSizeSemiFinishedProduct',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 0,
            rowIndex: 7,
            colIndex: 5,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_50_chars_01',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 0,
            colIndex: 0,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_50_chars_02',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 0,
            colIndex: 3,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_50_chars_03',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 0,
            colIndex: 6,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_50_chars_04',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 0,
            colIndex: 9,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_50_chars_05',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 1,
            colIndex: 0,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_50_chars_06',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 1,
            colIndex: 3,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_50_chars_07',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 1,
            colIndex: 6,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_50_chars_08',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 1,
            colIndex: 9,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_50_chars_09',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 2,
            colIndex: 0,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_50_chars_10',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 2,
            colIndex: 3,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_50_chars_11',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 2,
            colIndex: 6,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_50_chars_12',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 2,
            colIndex: 9,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_50_chars_13',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 3,
            colIndex: 0,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_50_chars_14',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 3,
            colIndex: 3,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_50_chars_15',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 3,
            colIndex: 6,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_50_chars_16',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 3,
            colIndex: 9,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_50_chars_17',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 4,
            colIndex: 0,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_50_chars_18',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 4,
            colIndex: 3,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_50_chars_19',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 4,
            colIndex: 6,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_50_chars_20',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 4,
            colIndex: 9,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
	{
        technicalName: 'text_50_chars_21',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 5,
            colIndex: 0,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_50_chars_22',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 5,
            colIndex: 3,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_50_chars_23',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 5,
            colIndex: 6,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_50_chars_24',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 5,
            colIndex: 9,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_50_chars_25',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 6,
            colIndex: 0,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_50_chars_26',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 6,
            colIndex: 3,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_50_chars_27',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 6,
            colIndex: 6,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_50_chars_28',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 6,
            colIndex: 9,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_50_chars_29',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 7,
            colIndex: 0,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_50_chars_30',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 7,
            colIndex: 3,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_50_chars_31',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 7,
            colIndex: 6,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_50_chars_32',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 7,
            colIndex: 9,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_50_chars_33',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 8,
            colIndex: 0,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_50_chars_34',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 8,
            colIndex: 3,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_50_chars_35',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 8,
            colIndex: 6,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_50_chars_36',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 8,
            colIndex: 9,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_50_chars_37',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 9,
            colIndex: 0,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_50_chars_38',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 9,
            colIndex: 3,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_50_chars_39',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 9,
            colIndex: 6,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_50_chars_40',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 9,
            colIndex: 9,
            colSpan: 3
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_1000_chars_01',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 10,
            colIndex: 0,
            colSpan: 12
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_1000_chars_02',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 11,
            colIndex: 0,
            colSpan: 12
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_1000_chars_03',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 12,
            colIndex: 0,
            colSpan: 12
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_1000_chars_04',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 13,
            colIndex: 0,
            colSpan: 12
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_1000_chars_05',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 14,
            colIndex: 0,
            colSpan: 12
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_1000_chars_06',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 15,
            colIndex: 0,
            colSpan: 12
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_1000_chars_07',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 16,
            colIndex: 0,
            colSpan: 12
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_1000_chars_08',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 17,
            colIndex: 0,
            colSpan: 12
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_1000_chars_09',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 18,
            colIndex: 0,
            colSpan: 12
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'text_1000_chars_10',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.maxLength(50),
            errorAttribute: 'maxlength',
            errorMessage: 'errors.max-length'
        }, {
            validator: alphanumericValidator(),
            errorAttribute: 'alphanumeric',
            errorMessage: 'errors.alphanumeric'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 19,
            colIndex: 0,
            colSpan: 12
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_001',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 20,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_002',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 20,
            colIndex: 4,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_003',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 20,
            colIndex: 8,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_004',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 21,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_005',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 21,
            colIndex: 4,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_006',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 21,
            colIndex: 8,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_007',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 22,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_008',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 22,
            colIndex: 4,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_009',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 22,
            colIndex: 8,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_010',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 23,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_011',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 23,
            colIndex: 4,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_012',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 23,
            colIndex: 8,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_013',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 24,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_014',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 24,
            colIndex: 4,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_015',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 24,
            colIndex: 8,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_016',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 25,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_017',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 25,
            colIndex: 4,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_018',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 25,
            colIndex: 8,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_019',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 26,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_020',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 26,
            colIndex: 4,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_021',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 26,
            colIndex: 8,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_022',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 27,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_023',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 27,
            colIndex: 4,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_024',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 27,
            colIndex: 8,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_025',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 28,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_026',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 28,
            colIndex: 4,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_027',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 28,
            colIndex: 8,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_028',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 29,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_029',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 29,
            colIndex: 4,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_030',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 29,
            colIndex: 8,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_031',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 30,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_032',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 30,
            colIndex: 4,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_033',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 30,
            colIndex: 8,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_034',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 31,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_035',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 31,
            colIndex: 4,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_036',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 31,
            colIndex: 8,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_037',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 32,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_038',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 32,
            colIndex: 4,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_039',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 32,
            colIndex: 8,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_040',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 33,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_041',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 33,
            colIndex: 4,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_042',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 33,
            colIndex: 8,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_043',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 34,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_044',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 34,
            colIndex: 4,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_045',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 34,
            colIndex: 8,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_046',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 35,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_047',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 35,
            colIndex: 4,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_048',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 35,
            colIndex: 8,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_049',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 36,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_050',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 36,
            colIndex: 4,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_051',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 36,
            colIndex: 8,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_052',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 37,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_053',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 37,
            colIndex: 4,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_054',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 37,
            colIndex: 8,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_055',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 38,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_056',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 38,
            colIndex: 4,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_057',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 38,
            colIndex: 8,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_058',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 39,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_059',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 39,
            colIndex: 4,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_060',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 39,
            colIndex: 8,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_061',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 40,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_062',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 40,
            colIndex: 4,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_063',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 40,
            colIndex: 8,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_064',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 41,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_065',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 41,
            colIndex: 4,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_066',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 41,
            colIndex: 8,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_067',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 42,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_068',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 42,
            colIndex: 4,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_069',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 42,
            colIndex: 8,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_070',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 43,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_071',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 43,
            colIndex: 4,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_072',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 43,
            colIndex: 8,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_073',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 44,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_074',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 44,
            colIndex: 4,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_075',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 44,
            colIndex: 8,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_076',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 45,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_077',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 45,
            colIndex: 4,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_078',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 45,
            colIndex: 8,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_079',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 46,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_080',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 46,
            colIndex: 4,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_081',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 46,
            colIndex: 8,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_082',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 47,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_083',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 47,
            colIndex: 4,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_084',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 47,
            colIndex: 8,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_085',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 48,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_086',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 48,
            colIndex: 4,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_087',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 48,
            colIndex: 8,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_088',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 49,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_089',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 49,
            colIndex: 4,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_090',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 49,
            colIndex: 8,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_091',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 50,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_092',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 50,
            colIndex: 4,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_093',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 50,
            colIndex: 8,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_094',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 51,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_095',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 51,
            colIndex: 4,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_096',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 51,
            colIndex: 8,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_097',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 52,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_098',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 52,
            colIndex: 4,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_099',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 52,
            colIndex: 8,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    {
        technicalName: 'number_20_digits_100',
        parentDefinitionName: null,
        required: false,
        validation: [{
            amount: 0,
            validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
            errorAttribute: 'pattern',
            errorMessage: 'errors.number-format'
        }],
        displayType: DisplayType.Input,
        displayOptions: undefined,
        layout: {
            group: 7,
            rowIndex: 53,
            colIndex: 0,
            colSpan: 4
        },
        isForTable: true,
        showInDetailView: true,
    },
    
    // {
    //     technicalName: 'co2EmissionFactor',
    //     parentDefinitionName: null,
    //     required: false,
    //     validation: [{
    //         amount: 0,
    //         validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
    //         errorAttribute: 'pattern',
    //         errorMessage: 'errors.number-format'
    //     }],
    //     displayType: DisplayType.Input,
    //     displayOptions: undefined,
    //     layout: {
    //         group: 6,
    //         rowIndex: 0,
    //         colIndex: 0,
    //         colSpan: 2
    //     },
    //     isForTable: true,
    //     showInDetailView: true,
    // },
    // {
    //     technicalName: 'idGaBi',
    //     parentDefinitionName: null,
    //     required: false,
    //     validation: [{
    //         amount: 0,
    //         validator: Validators.maxLength(20),
    //         errorAttribute: 'maxlength',
    //         errorMessage: 'errors.max-length'
    //     }, {
    //         validator: alphanumericValidator(),
    //         errorAttribute: 'alphanumeric',
    //         errorMessage: 'errors.alphanumeric'
    //     }],
    //     displayType: DisplayType.Input,
    //     displayOptions: undefined,
    //     layout: {
    //         group: 6,
    //         rowIndex: 0,
    //         colIndex: 0,
    //         colSpan: 2
    //     },
    //     isForTable: true,
    //     showInDetailView: true,
    // },
    // {
    //     technicalName: 'recycledContent',
    //     parentDefinitionName: null,
    //     required: false,
    //     validation: [{
    //         amount: 0,
    //         validator: Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$'),
    //         errorAttribute: 'pattern',
    //         errorMessage: 'errors.number-format'
    //     }],
    //     displayType: DisplayType.Input,
    //     displayOptions: undefined,
    //     layout: {
    //         group: 6,
    //         rowIndex: 0,
    //         colIndex: 0,
    //         colSpan: 2
    //     },
    //     isForTable: true,
    //     showInDetailView: true,
    // },
];
