import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';


// Configuration
export class IConfiguration {

    serverUrl: string;
    auth_EntraId:{
        authUrl: string,
        clientId: string,
        tenantId: string,
        redirect_uri: string,
        scope: string,
        identifier: string
    };
    auth_groupidp: {
        authUrl: string,
        clientId: string,
        scope: string,
        redirect_uri: string
    };
    backend: {
        serverUrl: string,
        apiPath: string
    };
    ratingUrl: string;
}


// Service to provide the configuration
@Injectable({
    providedIn: 'root',
})
export class ConfigurationService extends IConfiguration {

    // Constructor
    constructor(private http: HttpClient) {
        super();
    }

    // Load config
    Load() {
        const jsonFile = 'config/config.' + environment.environment + '.json';
        return new Promise<void>((resolve, reject) => {
            this.http.get(jsonFile, {
                headers: new HttpHeaders({
                    'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
                    'Pragma': 'no-cache',
                    'Expires': '0'
                })
            }).toPromise().then((response: IConfiguration) => {
                let config = <IConfiguration>response;
                for (let key in config) {
                    this[key] = config[key];
                }
                resolve();
            });
        });
    }
}