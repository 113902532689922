import * as $ from "jquery";


/**
 * Store managing the state for the entry task creation.
 */
export class PorscheTools {
    /**
      * Delimiter
      */
    public static readonly paramDelimiter = "~^";

    /**
     * Delimiter
     */
    public static readonly paramDelimiter2 = "||";

    constructor() {
        // Nothing
    }

    /**
     * Open PDF Stream in new Tab or not
     *
     * @param pdfFileBase64
     * @param onNewTab
     * @param onlyOpen
     */
    public static OpenPDFInBrowser(pdfFileBase64: string, fileTitle: string, onlyOpen = false) {
        if (PorscheTools.stringIsNullOrEmpty(pdfFileBase64)) {
            return;
        }

        const binaryString = window.atob(pdfFileBase64);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            const ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }

        if (PorscheTools.stringIsNullOrEmpty(fileTitle)) {
            const dateVal = new Date();
            fileTitle = "pdf_" + PorscheTools.getFormatStringDateFromDate(dateVal);
            fileTitle = fileTitle.replace(":", "_");
        }

        // const blob = new Blob([bytes], { type: 'application/pdf' });
        const file = new File([bytes], fileTitle, { type: "application/pdf" });
        const url = URL.createObjectURL(file);

        if (PorscheTools.BoolVal(onlyOpen)) {
            const w = window.open(url, "_blank");
            setTimeout(() => {
                w.document.title = fileTitle;
            }, 1000);
        } else {
            const a = document.createElement("a");
            a.href = url;
            a.setAttribute("download", fileTitle);
            a.setAttribute("title", fileTitle);
            a.setAttribute("target", "_blank");
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        }
    }

    /**
     * Decode html Text From Server. Encode in Server with => HttpUtility.HtmlEncode([htmltext])
     *
     * @param htmlText
     */
    public static decodeHTML(htmlText: string): string {
        return $("<textarea/>").html(htmlText).text();
    }

    /**
     * Encode html Text for Server. DEcode on server with => HttpUtility.UrlDecode([text], Encoding.UTF8);
     *
     * @param htmlText
     */
    public static encodeHTML(htmlText: string): string {
        return encodeURIComponent(htmlText);
    }

    /**
     * Converts the first Letter to Upper case from Cluu Entity Object for the JSON parse on JavaScript.
     *
     * @param jsonValue
     */
    public static convertFirstPropLetterToUpperFromJson(jsonValue: string): string {
        jsonValue = this.fixJsonVal(jsonValue, "{\"");
        jsonValue = this.fixJsonVal(jsonValue, ",\"");

        return jsonValue;
    }

    private static fixJsonVal(jsonString: string, propstartValue: string) {
        const findValLength = propstartValue.length;
        let doIt = true;
        let i = 0;
        while (doIt) {
            i = jsonString.indexOf(propstartValue, i);
            if (i >= 0) {
                const startI = i + findValLength;
                let sourceVal = jsonString.substr(startI, 1);
                sourceVal = sourceVal.toLocaleLowerCase();
                jsonString = jsonString.substring(0, startI) + sourceVal + jsonString.substring(startI + 1);
                i = startI + 1;
            } else {
                doIt = false;
            }
        }

        return jsonString;
    }

    /**
     * Gives the item for Update Insert data
     *
     * @param propertyName
     * @param value
     */
    public static getPropertyValueItem(propertyName: string, value: any): string {
        let propvalue = "";
        if (PorscheTools.isNullOrUndefined(value)) {
            propvalue = "null";
        } else {
            propvalue = String(value);
        }
        const propValue = propertyName + this.paramDelimiter + propvalue;

        return propValue;
    }

    /**
     * Format TypeScript Date String Value: 01.01.0001 00:00:00
     */
    public static getFormatStringDateFromDateString(dateVal: string, withTime = true, withSeconds = true): string {
        if (PorscheTools.stringIsNullOrEmpty(dateVal)) {
            return "";
        } else {
            const date = new Date(dateVal);

            return (
                ("0" + date.getDate()).slice(-2).toString() +
                "." +
                ("0" + (date.getMonth() + 1)).slice(-2).toString() +
                "." +
                date.getFullYear().toString() +
                (withTime
                    ? (" " +
                        ("0" + date.getHours()).slice(-2).toString() +
                        ":" +
                        ("0" + date.getMinutes()).slice(-2).toString() +
                        (withSeconds ? ":" + ("0" + date.getSeconds()).slice(-2).toString() : ""))
                    : "")
            );
        }
    }

    /**
     * Format TypeScript Date String Value: 01.01.0001 00:00:00
     */
    public static getFormatStringDateFromDate(dateVal: Date, withTime = true, withSeconds = true): string {
        if (PorscheTools.isNullOrUndefined(dateVal)) {
            return "";
        } else {
            return PorscheTools.getFormatStringDateFromDateString(dateVal.toString(), withTime, withSeconds);
        }
    }

    /**
     * Gives Curent Date in String for C# Format  0001/01/01 00:00:00.0000
     */
    public static getDateTimeNow(): string {
        const dateVal = new Date();

        return (
            dateVal.getFullYear() +
            "/" +
            (dateVal.getMonth() + 1) +
            "/" +
            dateVal.getDate() +
            " " +
            dateVal.getHours() +
            ":" +
            dateVal.getMinutes() +
            ":" +
            dateVal.getSeconds() +
            "." +
            dateVal.getMilliseconds()
        );
    }

    /**
     * Gives  Date in String for C# Format  0001/01/01 00:00:00.0000
     */
    public static getDate(dateVal: Date): string {
        return (
            dateVal.getFullYear() +
            "/" +
            (dateVal.getMonth() + 1) +
            "/" +
            dateVal.getDate() +
            " " +
            dateVal.getHours() +
            ":" +
            dateVal.getMinutes() +
            ":" +
            dateVal.getSeconds() +
            "." +
            dateVal.getMilliseconds()
        );
    }


    /**
     * check the String is NUll or Empty or Undefined
     *
     * @param value
     */
    public static stringIsNullOrEmpty(value: string): boolean {
        const newVal = value;

        return newVal === "" || PorscheTools.isNullOrUndefined(value);
    }

    /**
     * Vaidate the Values from rquired fields
     */
    public static isNumber(value: any): boolean {
        if (PorscheTools.isNullOrUndefined(value)) {
            return false;
        }

        return $.isNumeric(value);
    }

    /**
     * Vaidate the Values from rquired fields
     */
    public static NumberVal(value: any): number {
        if (!PorscheTools.isNumber(value)) {
            return null;
        }

        return Number(value);
    }

    /**
     * Vaidate the Values from rquired fields
     */
    public static isNullOrUndefined(value: any) {
        return value === null || value === undefined;
    }

    /**
     * Vaidate the Values from rquired fields
     */
    public static BoolVal(value: any) {
        if (PorscheTools.isNullOrUndefined(value)) {
            return false;
        }

        let test = String(value);
        test = test.trim().toLowerCase();
        const val = test === "true";

        return val;
    }

    /**
     * Vaidate the Values from rquired fields
     */
    public static BoolToText(value: any): string {
        let val = "Keine Auswahl";
        if (PorscheTools.isNullOrUndefined(value)) {
            return val;
        }

        let test = String(value);
        test = test.trim().toLowerCase();
        val = (test === "true") ? "Ja" : "Nein";

        return val;
    }

    /**
     * Vaidate the Values from rquired fields
     */
    public static StringVal(value: any) {
        if (PorscheTools.isNullOrUndefined(value)) {
            return "";
        }

        return String(value);
    }

    /**
     * Validate the Values from rquired fields
     *
     * @param value
     */
    public static ValidateKW(year: string, value: any, isPast: boolean): boolean {
        let data = null;
        if (!PorscheTools.stringIsNullOrEmpty(value)) {
            data = value;
        }

        let dateNow = new Date();
        if (PorscheTools.isNumber(year)) {
            const yearNumber = Number(year);
            if (yearNumber > dateNow.getFullYear() || isPast) {
                dateNow = new Date(Number(year), 0, 1);
            }
        }

        let isValid = !PorscheTools.isNullOrUndefined(data) && PorscheTools.isNumber(data);

        if (isValid) {
            const numVal = Number(data);
            const kwBefore = this.GetKW(dateNow);
            const lastKW = this.GetLastKWInYear(year);
            isValid = numVal >= kwBefore && numVal <= lastKW;
        }

        return isValid;
    }

    /**
     * Returns the current week of the year
     */
    public static Equals(value1: string, value2: string): boolean {
        const val = PorscheTools.StringVal(value1).trim().toLocaleUpperCase() === PorscheTools.StringVal(value2).trim().toLocaleUpperCase();

        return val;
    }

    /**
     * Returns the current week of the year
     */
    public static GetKW(val: Date): number {
        const today = new Date(val.getFullYear(), val.getMonth(), val.getDate());
        const yearStart = new Date(today.getFullYear(), 0, 1);
        const dayOfYear = ((today.valueOf() - yearStart.valueOf()) / 86400000);
        let week = Math.ceil(dayOfYear / 7);
        if (week == 0) {
            week = 1;
        } else {
            if (today.getDay() == 4) {
                week = week + 1;
            }

            if (today.getDay() == 5
                && yearStart.getDay() == 4) {
                week = week + 1;
            }
        }

        return week;
    }

    /**
     * Returns the current week of the year
     */
    public static GetDaysInDate(startDate: Date, endDate: Date, dayNumber: number) {
        const result = [];
        const current = new Date(startDate);
        current.setDate(current.getDate() + (dayNumber - current.getDay() + 7) % 7);

        while (current < endDate) {
            result.push(new Date(+current));
            current.setDate(current.getDate() + 7);
        }

        return result.length;
    }

    /**
     * Returns the current week of the year
     */
    public static GetLastKWInYear(year: string): number {
        let yearDate = new Date(new Date().getFullYear(), 11, 31);
        if (PorscheTools.isNumber(year)) {
            yearDate = new Date(Number(year), 11, 31);
        }

        const currentdate = yearDate;
        const result = this.GetKW(currentdate);

        return result;
    }

    /**
     * Validate the Values from rquired fields
     *
     * @param value
     * @param maxYear
     */
    public static ValidateYear(value?: any, maxYear?: number): boolean {
        let data = null;
        if (!PorscheTools.stringIsNullOrEmpty(value)) {
            data = value;
        }

        let isValid = !PorscheTools.isNullOrUndefined(data) && PorscheTools.isNumber(data);

        if (isValid) {
            const numVal = Number(data);
            const test = String(numVal);
            if (test.length == 4) {
                const currentYear = new Date().getFullYear();
                isValid = numVal >= currentYear;

                if (isValid && !PorscheTools.isNullOrUndefined(data) && PorscheTools.isNumber(maxYear)) {
                    isValid = numVal <= maxYear;
                }
            } else {
                isValid = false;
            }
        }

        return isValid;
    }


    /**
     * Show a short fast info dialog
     */
    public static ValidateNumber(value: string, canEmpty: boolean, maxLength: number) {
        let isValid = canEmpty && PorscheTools.stringIsNullOrEmpty(value);
        if (!isValid && !PorscheTools.stringIsNullOrEmpty(value)) {
            isValid = PorscheTools.isNumber(value);
            if (isValid) {
                if (PorscheTools.isNumber(maxLength)) {
                    isValid = value.length <= maxLength;
                }
            }
        }

        return isValid;
    }

    /**
     * Show a short fast info dialog
     */
    public static ReplaceAll(value: string, searchValue: string, replaceValue: string) {
        return value.split(searchValue).join(replaceValue);
    }
}
