/**
 * Data Class for KeyValueData
 */
export class KeyValueData {
    /**
     * Set or gets Value
     */
    public key: string;

    /**
     * Set or gets Value
     */
    public value: any;
}

