<mat-card appearance="outlined">
    <mat-card-title>{{title}}</mat-card-title>
    <div id="table-container" class="scrollable-content">
        <mat-table [dataSource]="materialsData">
            <!-- Actions -->
            <!-- <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>{{'common.actions' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let material">
                    <mat-menu #userWorkflowMenu="matMenu">
                        <div *ngFor="let output of workflowUsers" id="output">{{GetHeader(output) | translate}}:
                            {{GetValue(output) | translate}}</div>
                    </mat-menu>
                </mat-cell>
            </ng-container> -->

            <!-- Columns -->
            <ng-container *ngFor="let col of displayedColumns">
                <ng-container *ngIf="col != 'actions'" [matColumnDef]="col">
                    <mat-header-cell *matHeaderCellDef>{{'material.attribute.' + col | translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let material">{{formatService.toReadView(material[col], col)}}</mat-cell>
                </ng-container>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"
                [ngClass]="{'make-gold': row.revision > 0 , 'make-green': row.revision == 0}"></mat-row>
        </mat-table>
    </div>
</mat-card>