<div id="content">
    <app-material-detail #materialForm [material]="material" [enabledInputs]="workflowWritePermissions"></app-material-detail>

    <p-headline variant="headline-4">{{'grouping.workflow' | translate}}</p-headline>
    <form [formGroup]="form">
        <p-grid>
            <p-grid-item size="12" *ngIf="workflowStep?.stepType != 0">
                <p-text-field-wrapper [label]="'workflow.step' | translate"> 
                    <input type="text" formControlName="currentStepTranslation"/>
                </p-text-field-wrapper>
            </p-grid-item>

            <p-grid-item size="12" *ngIf="workflowStep?.stepType != 0">
                <p-text-field-wrapper [label]="'workflow.last-editor' | translate"> 
                    <input type="text" formControlName="lastEditor"/>
                </p-text-field-wrapper>
            </p-grid-item>
            <p-grid-item size="12">
                <p-select-wrapper [label]="('workflow.approvers' | translate) + ' *'" 
                [state]="(!form.get('approverInput').untouched && form.get('approvers').invalid) ? 'error' : 'none'" 
                [message]="'errors.invalid-input' | translate"> 
                    <select formControlName="approverInput">
                        <option [ngValue]="null">{{'common.select' | translate}}</option>
                        <option *ngFor="let approver of approvers" [ngValue]="approver">{{formatService.formatUserName(approver)}}</option>
                    </select>
                </p-select-wrapper>
                <div *ngIf="form.get('approvers').value?.length > 0" class="list-item-container">
                    <div *ngFor="let approver of form.get('approvers').value" class="list-item">
                        <p-button variant="tertiary" icon="close" (click)="removeApprover(approver)">{{formatService.formatUserName(approver)}}</p-button>
                    </div>
                </div>
            </p-grid-item>
        </p-grid>
    </form>

    <p-divider></p-divider>

    <p-grid>
        <p-grid-item size="12">
            <p-button variant="secondary" icon="close" (click)="onAbort()">{{'workflow.abort' | translate}}</p-button>
            <p-button *ngIf="showDeny" variant="secondary" icon="arrow-left" (click)="onDeny()">{{'workflow.reject' | translate}}</p-button>
            <p-button variant="primary" icon="check" (click)="onSubmit()">{{'workflow.confirm' | translate}}</p-button>
        </p-grid-item>  
    </p-grid>
</div>