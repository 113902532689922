// Defines a material with all its attributes
export class Material {

    public id: string;
    public revision: number;
    public drawingEntryWithoutID : string;
    public drawingEntryWithID : string;
    public drawingEntryWithIDshort : string;
    public groupMaterialID: string;
    public dataSetType: string;
    public description: string;
    public description2: string;
    public description3: string;
    public regulationType: string;
    public regulationNumber: string;
    public legalAuthority: string;
    public issueDate: Date;
    public shortName: string;
    public features: string;
    public additionalInformation: string;
    public materialNumber: string;
    public vdaMainGroup: number;
    public vdaSubGroup: string;
    public vdaIdentification: string;
    public name: string;
    public alias: string[];
    public idmsNodeId: string[];
    public missId: string;
    public language: string;
    public rawMaterial: string;
    public semiFinishedProduct: string;
    public treatment: string;
    public manufacturingProcess: string;
    public finish: string;
    public vdaVersionIdentifier: string;
    public isDensity: boolean;
    public minimumDensity: number;
    public typicalDensity: number;
    public maximumDensity: number;
    public minimumWeightPerUnitArea: number;
    public typicalWeightPerUnitArea: number;
    public maximumWeightPerUnitArea: number;
    public minimumElasticLimit: number;
    public typicalElasticLimit: number;
    public maximumElasticLimit: number;
    public minimumTensileStrength: number;
    public typicalTensileStrength: number;
    public maximumTensileStrength: number;
    public minimumElongation: number;
    public typicalElongation: number;
    public maximumElongation: number;
    public specimenType: string;
    public typicalEModulus: number;
    public typicalPoissonRatio: number;
    public typicalThermalExpansion: number;
    public isDefaultGeneralComment: boolean;
    public defaultGeneralComment: string;
    public customGeneralComment: string;
    public generalComment: string;
    public useStatusCadSystemsGroup: string;
    public useStatusCadSystemsGroupDate: Date;
    public useStatusCadSystemsAudi: string;
    public useStatusCadSystemsAudiDate: Date;
    public useStatusCadSystemsPorsche: string;
    public useStatusCadSystemsPorscheDate: Date;
    public useStatusCadSystemsMAN: string;
    public useStatusCadSystemsMANDate: Date;
    public useStatusCadSystemsScania: string;
    public useStatusCadSystemsScaniaDate: Date;
    public useStatusCadSystemsVW: string;
    public useStatusCadSystemsVWDate: Date;
    public useStatusCadSystemsMarkets: string;
    public standardStatus: string;
    public nolisClassification: string;
    public inflammability: boolean;
    public idPredecessor: string[];
    public idSuccessor: string[];
    public previousPagMaterialId: string;
    public applicableNorms: string;
    public minimumSizeSemiFinishedProduct: number;
    public maximumSizeSemiFinishedProduct: number;
    public lastModificationDate: Date;
    // public co2EmissionFactor: number;
    // public idGaBi: string;
    // public recycledContent: number;
    public hasUseRestriction: boolean;
    public useRestricitonDetail: string;

    constructor() {
        this.vdaVersionIdentifier = '2016-01-01';
    }
}
