import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth/auth.service';

@Component({
    selector: 'app-silent-callback',
    template: ''
})
export class AuthSilentCallbackComponent implements OnInit {

    // Constructor
    constructor(private router: Router, private authService: AuthService) {
    }

    // OnInit
    async ngOnInit() {
        await this.authService.SilentSignInAuthenticationAsync();
    }
}
