import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-userguide',
  templateUrl: './userguide.component.html',
  styleUrls: ['./userguide.component.css']
})
export class UserguideComponent implements OnInit {

  constructor(private httpClient: HttpClient) { }

  ngOnInit(): void {
  }

  getFile() {
    window.open('./assets/files/Anwender-Dokumentation Material Master_1.4.0.pdf');
  }
  
}
