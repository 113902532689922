<div id="content" class="mainContainerOverviewBody">
    <div class="mainContainerOverviewHeader">


        <div class="mainContainerOverviewSearch">
            <form [formGroup]="searchForm" (ngSubmit)="search()">
                <p-text-field-wrapper [label]="'common.search-placeholder' | translate">
                    <input [type]="'search'" formControlName="search" />
                </p-text-field-wrapper>
            </form>

        </div>
    </div>

    <div id= "table-container" class="mainContainerOverviewTableContainer">
        <mat-table [dataSource]="data" matSort matSortActive="name" matSortDirection="asc" matSortDisableClear>
            <!-- Actions -->
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>{{'common.actions' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <p-button variant="tertiary" hide-label="true" icon="view" [routerLink]="['/administration', user.sub]"></p-button>
                    <p-button class="delete-button" variant="tertiary" hide-label="true" icon="delete" (click)="delete(user.sub)" [disabled]="user.sub == userService.currentUser.sub"></p-button>
                </mat-cell>
            </ng-container>

            <!-- Columns -->
            <ng-container *ngFor="let col of displayedColumns">
                <ng-container *ngIf="col != 'actions'" [matColumnDef]="col">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'user.' + col | translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let user">{{user[col]}}</mat-cell>
                </ng-container>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
    </div>  
    
    <div id="footer" class="mainContainerOverviewFooter">
        <div>
            <p-pagination [totalItemsCount]="userService.count" [itemsPerPage]="itemsPerPage" [activePage]="activePage" (pageChange)="paging($event)"></p-pagination>
        </div>
        <div class="mainContainerOverviewSelect">
            <mat-form-field appearance="fill">
                <mat-label>{{'common.itemsPerPage' | translate}}</mat-label>
                <mat-select [(ngModel)]="itemsPerPage">
                    <mat-option [value]="10">10</mat-option>
                    <mat-option [value]="25">25</mat-option>
                    <mat-option [value]="50">50</mat-option>
                    <mat-option [value]="100">100</mat-option>
                    <mat-option [value]="250">250</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div>
