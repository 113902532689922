import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { PorscheTools } from "app/common/PorscheToolsStore";
import { Material } from "app/model/material";
import { UserProfile } from "app/model/userProfile";
import { WorkflowStepType } from "app/model/workflowStepType";
import { UserService } from "app/services/auth/user.service";
import { FormatService } from "app/services/format.service";
import { MaterialService } from "app/services/material.service";


@Component({
  selector: 'app-import-overview',
  templateUrl: './import-overview.component.html',
  styleUrls: ['./import-overview.component.css']
})

export class ImportOverviewComponent implements OnInit {

  file: any;
  isUploaded: boolean;
  isDisabled: boolean;
  isVisible: boolean;
  approverInput: UserProfile;
  importApprovers: UserProfile[];
  form: FormGroup;
  currentStep: WorkflowStepType;
  importedMaterials: Material[];

  currentImportFileStateId: string;


  constructor(public translateService: TranslateService, public materialService: MaterialService, public userService: UserService, public formatService: FormatService) {
    this.currentStep = WorkflowStepType.Init;
    this.isDisabled = true;
    this.isUploaded = false;
    this.isVisible = true;
    this.currentImportFileStateId = null;
  }

  ngOnInit(): void {
    this.getImportApprovers(this.currentStep);
    this.form = new FormGroup({
      approverInput: new FormControl(null, []),
      importApprovers: new FormControl([], [Validators.required])
    });
  }

  getFile(event: any) {
    this.file = event.target.files[0];
    console.log('file', this.file);
  }

  async uploadFileAsync() {
    await this.executeImportAsync(this.file);
  }

  async executeImportAsync(file: any) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async (e) => {
      console.log(reader.result);
      const base64String = reader.result.toString();
      const base64StringSplitted = base64String.split(',')[1];
      // const response = await this.materialService.ImportMaterialsAsync(base64StringSplitted, this.file.name, this.approverInput.id);
      const response = await this.materialService.ImportMaterialsAsync(base64StringSplitted, this.file.name);
      if (!PorscheTools.stringIsNullOrEmpty(response.resultErrorMessage)) {
        alert(response.resultErrorMessage);
      }
      this.currentImportFileStateId = response.importFileStateId;
    };
    reader.onerror = (err) => {
      console.log('Error: ', err);
    };
  }

  async abortImportAsync() {
    const response = await this.materialService.UpdateImportWorkflowAsync(null, this.currentImportFileStateId, true);
    this.isVisible = false;
  }

  async setWorkflowApproverAsync() {
    const response = await this.materialService.UpdateImportWorkflowAsync(this.approverInput.id, this.currentImportFileStateId, false);
    this.isVisible = false;
  }

  async getImportApprovers(currentStep: WorkflowStepType) {
    const response = await this.userService.GetImportApproversAsync(currentStep);
    this.importApprovers = response.userProfiles;
  }

  getApproverInput(approverInput: UserProfile) {
    this.approverInput = approverInput;
    if (!PorscheTools.isNullOrUndefined(approverInput)) {
      this.isDisabled = false;
    }
  }
}
