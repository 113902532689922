import { WorkflowStep } from './workflowStep';

// Contains the current workflow for a material (not the workflow template itself)
export class Workflow {

    // Is the workflow still in progress or is it finished?
    public inProgress: boolean = true;

    // Workflow steps that were done (the last one is the current one, if the workflow is still in progress)
    public steps: WorkflowStep[] = [];

    // Current step
    public currentStep(): WorkflowStep {
        if (this.inProgress && this.steps.length > 0) {
            return this.steps[this.steps.length - 1];
        }
        return null;
    }
}
