import { Component, Input, OnInit } from "@angular/core";
import { Material } from "app/model/material";
import { FormatService } from "app/services/format.service";
import { MaterialService } from "app/services/material.service";
import { MaterialDefinitionService } from "app/services/materialDefinition.service";

import { PorscheTools } from "../../../common/PorscheToolsStore";
import { MaterialDto } from "../../../dto/MaterialDto";

@Component({
  selector: 'app-import-material-temp-control',
  templateUrl: './import-material-temp-control.component.html',
  styleUrls: ['./import-material-temp-control.component.css']
})
export class ImportMaterialTempControlComponent implements OnInit {

  // Material
  private _importFileStateId: string;
  @Input() set importFileStateId(val: string) {
    if (this._importFileStateId != val) {
      this._importFileStateId = val;
      this.LoadMaterialsAsync();
    }
  }
  get importFileStateId(): string {
    return this._importFileStateId;
  }

  public title:string;

  // Data source
  public materialsData: Material[];

  // Displayed columns
  public displayedColumns: string[] = [
    // 'actions'
  ];

  constructor(
    public materialService: MaterialService,
    public materialDefinitionService: MaterialDefinitionService,
    public formatService: FormatService) {

    // Set displayed columns
    materialDefinitionService.sortedMaterialDefinitions.forEach(group =>
      group.forEach(row =>
        row.forEach(def => this.displayedColumns.push(def.technicalName))
      )
    );
  }

  ngOnInit(): void {
  }


  private async LoadMaterialsAsync() {
    if (!PorscheTools.stringIsNullOrEmpty(this.importFileStateId)) {
      const materialDto = await this.materialService.GetImportFileStateAsync(this.importFileStateId) as MaterialDto;
      const materials = materialDto?.importFileState?.materialTemp?.materials;
      this.title = materialDto?.importFileState?.filename;
      this.materialsData = materials;
    }
  }
}
