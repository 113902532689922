import { HttpClient, HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatChipsModule } from "@angular/material/chips";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { PorscheDesignSystemModule } from "@porsche-design-system/components-angular";
import { forkJoin, from, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AdminDetailComponent } from "./components/admin-detail/admin-detail.component";
import { AdminOverviewComponent, AdminOverviewDeleteDialog } from "./components/admin-overview/admin-overview.component";
import { AuthCallbackComponent } from "./components/auth/auth-callback.component";
import {
    ImportMaterialTempControlComponent,
} from "./components/controls/import-material-temp-control/import-material-temp-control.component";
import { HeaderComponent, LogoutDialog, OSOLicenseDialog } from "./components/header/header.component";
import { DenyImportDialog, ImportInspectComponent } from "./components/import-inspect/import-inspect.component";
import { ImportOverviewComponent } from "./components/import-overview/import-overview.component";
import { MaterialDetailComponent } from "./components/material-detail/material-detail.component";
import { MaterialInspectComponent } from "./components/material-inspect/material-inspect.component";
import { MaterialOverviewComponent } from "./components/material-overview/material-overview.component";
import { WorkflowDetailComponent } from "./components/workflow-detail/workflow-detail.component";
import { WorkflowOverviewComponent } from "./components/workflow-overview/workflow-overview.component";
import { AuthGuard } from "./helpers/auth.guard";
import { ConfigurationService } from "./services/config/config.service";
import { MaterialDefinitionService } from "./services/materialDefinition.service";
import { BackendRequestService } from "./services/request/backendRequest.service";
import { UserguideComponent } from './components/userguide/userguide.component';
import { LoginComponent } from './components/login/login.component';
import { AuthService } from "./services/auth/auth.service";

//  import { FlexLayoutModule } from '@angular/flex-layout';


// Called before the app is loaded
export function Init(config: ConfigurationService) {
    return () => config.Load();
}

// Localization
export class LocalizationLoader implements TranslateLoader {

    private translateHttpLoader: TranslateHttpLoader;

    constructor(private http: HttpClient, private backend: BackendRequestService) {
        this.translateHttpLoader = new TranslateHttpLoader(http);
    }

    public getTranslation(lang: string): Observable<any> {
        return forkJoin([
            this.translateHttpLoader.getTranslation(lang),
            from(this.backend.Get<Object>('Translation/I18n', { 'languageKey': lang }))
        ]).pipe(map(
            (res: any) => {
                return { ...res[0], ...(res[1] != '' ? JSON.parse(res[1]) : {}) };
            }
        ));
    }
}


@NgModule({
    declarations: [
        AppComponent,
        AuthCallbackComponent,
        MaterialOverviewComponent,
        HeaderComponent,
        WorkflowOverviewComponent,
        WorkflowDetailComponent,
        MaterialDetailComponent,
        MaterialInspectComponent,
        AdminOverviewComponent,
        AdminDetailComponent,
        AdminOverviewDeleteDialog,
        LogoutDialog,
        OSOLicenseDialog,
        ImportOverviewComponent,
        ImportInspectComponent,
        ImportMaterialTempControlComponent,
        DenyImportDialog,
        UserguideComponent,
        LoginComponent
    ],

    imports: [
        // Angular 10
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,

        // Porsche Design
        PorscheDesignSystemModule,

        // Angular Material 10
        MatTableModule,
        MatIconModule,
        MatToolbarModule,
        MatButtonModule,
        MatBadgeModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatExpansionModule,
        MatDividerModule,
        MatSortModule,
        MatPaginatorModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatChipsModule,
        MatMenuModule,
        MatSidenavModule,
        MatDialogModule,
        MatCardModule,
        MatProgressBarModule,
        MatTooltipModule,

        // Localization
        TranslateModule.forRoot({
            defaultLanguage: 'de',
            loader: {
                provide: TranslateLoader,
                useClass: LocalizationLoader,
                deps: [HttpClient, BackendRequestService]
            }
        })
    ],

    providers: [
        ConfigurationService,
        AuthGuard,
        {
            provide: APP_INITIALIZER,
            useFactory: Init,
            deps: [ConfigurationService],
            multi: true
        },
        MaterialDefinitionService
    ],

    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
