import { AttributeDefinition, MaterialDefinition } from "../model/materialDefinition";

export class MaterialDefinitionService {

    // Material definitions
    private _materialDefinitions: AttributeDefinition[];
    get materialDefinitions() : AttributeDefinition[] {
        return this._materialDefinitions;
    }

    // Sorted definitions (first by group, then by row, then by column, then by position in materialDefinition)
    private _sortedMaterialDefinitions: AttributeDefinition[][][] = [];
    get sortedMaterialDefinitions() : AttributeDefinition[][][] {
        return this._sortedMaterialDefinitions;
    }

    // Sorted and filtered definitons for MaterialOverview
    private _sortedMaterialDefinitionsTable: AttributeDefinition[][][] = [];
    get sortedMaterialDefinitionsTable() : AttributeDefinition[][][] {
        return this._sortedMaterialDefinitionsTable;
    }

    // Sorted and filtered definitons for MaterialDetail
    private _sortedMaterialDefinitionsDetail: AttributeDefinition[][][] = [];
    get sortedMaterialDefinitionsDetail() : AttributeDefinition[][][] {
        return this._sortedMaterialDefinitionsDetail;
    }

    // Constructor
    constructor() {
        this._materialDefinitions = MaterialDefinition;
        this.sortListDetail();
        this.sortListTable();
    }

    private sortListTable(){
        let tmp = this._materialDefinitions.sort((a, b) => 
            (a.layout.group < b.layout.group) ||
            (a.layout.group == b.layout.group && a.layout.rowIndex < b.layout.rowIndex) ||
            (a.layout.group == b.layout.group && a.layout.rowIndex == b.layout.rowIndex && a.layout.colIndex < b.layout.colIndex) 
            ? -1 : 1);
        let lastGroup;
        let lastRow;
        let lastCol;
        let test = tmp.filter(def => def.isForTable==true);
        test.forEach(attributeDefinition => {

            // Prepare
            if (lastGroup != attributeDefinition.layout.group) {
                this._sortedMaterialDefinitions.push([]);
                lastRow = undefined;
            }
            if (lastRow != attributeDefinition.layout.rowIndex) {
                this._sortedMaterialDefinitions[this._sortedMaterialDefinitions.length-1].push([]);
                lastCol = 0;
            }

            // Adjust
            attributeDefinition.layout.offset = attributeDefinition.layout.colIndex - lastCol;
            this._sortedMaterialDefinitions[this._sortedMaterialDefinitions.length-1][this._sortedMaterialDefinitions[this._sortedMaterialDefinitions.length-1].length-1].push(attributeDefinition);
        
            // Store
            lastGroup = attributeDefinition.layout.group;
            lastRow = attributeDefinition.layout.rowIndex;
            lastCol = attributeDefinition.layout.colIndex + attributeDefinition.layout.colSpan;
        });
    }

    private sortListDetail(){
        let tmp = this._materialDefinitions.sort((a, b) => 
            (a.layout.group < b.layout.group) ||
            (a.layout.group == b.layout.group && a.layout.rowIndex < b.layout.rowIndex) ||
            (a.layout.group == b.layout.group && a.layout.rowIndex == b.layout.rowIndex && a.layout.colIndex < b.layout.colIndex) 
            ? -1 : 1);
        let lastGroup;
        let lastRow;
        let lastCol;
        let test = tmp.filter(def => def.showInDetailView==true);
        test.forEach(attributeDefinition => {

            // Prepare
            if (lastGroup != attributeDefinition.layout.group) {
                this._sortedMaterialDefinitionsDetail.push([]);
                lastRow = undefined;
            }
            if (lastRow != attributeDefinition.layout.rowIndex) {
                this._sortedMaterialDefinitionsDetail[this._sortedMaterialDefinitionsDetail.length-1].push([]);
                lastCol = 0;
            }

            // Adjust
            attributeDefinition.layout.offset = attributeDefinition.layout.colIndex - lastCol;
            this._sortedMaterialDefinitionsDetail[this._sortedMaterialDefinitionsDetail.length-1][this._sortedMaterialDefinitionsDetail[this._sortedMaterialDefinitionsDetail.length-1].length-1].push(attributeDefinition);
        
            // Store
            lastGroup = attributeDefinition.layout.group;
            lastRow = attributeDefinition.layout.rowIndex;
            lastCol = attributeDefinition.layout.colIndex + attributeDefinition.layout.colSpan;
        });
    }
}