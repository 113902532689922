import { AbstractControl, ValidatorFn } from '@angular/forms';

export function alphanumericValidator(additionalChars: string[] = []): ValidatorFn {

    const standardValues: string[] = [
        'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
        'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
        '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
        '-', '#', ' '
    ];
    const checkValues: string[] = standardValues.concat(additionalChars);

    return (control: AbstractControl): { [key: string]: any } | null => {
        if (control.value) {
            for (const char of [...control.value]) {
                if (checkValues.findIndex(c => c === char) === -1) {
                    if (additionalChars.length !== 0) {
                        return {
                            alphanumeric: {
                                additionalChars: additionalChars
                            }
                        };
                    }
                    return {
                        alphanumeric: {}
                    };
                }
            }
        }
        return null;
    };
}
//TODO: Add String-Array Validator
