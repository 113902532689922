import { Component } from '@angular/core';
import { WorkflowService } from 'app/services/workflow.service';
import { UserService } from 'app/services/auth/user.service';
import { FormatService } from 'app/services/format.service';
import { UserProfile } from 'app/model/userProfile';

@Component({
    selector: 'app-workflow-overview',
    templateUrl: './workflow-overview.component.html',
    styleUrls: ['./workflow-overview.component.css']
})
export class WorkflowOverviewComponent {

    // Displayed columns
    public displayedColumns: string[] = [
        'actions',
        'materialCode',
        'creator',
        'stepType'
    ];

    // Load all users
    users: { [id: string] : UserProfile } = {};

    // Constructor
    constructor(public workflow: WorkflowService, private user: UserService, public formatService: FormatService) {
        this.workflow.changed.subscribe(x => {
            this.users = {};
            this.workflow.data.forEach(async d => {
                let id = d.workflow.currentStep().creator;
                this.users[id] = await this.user.GetUser(id);
            });
        });
    }
}

 