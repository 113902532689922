<div id="overlay" *ngIf="httpRequestService.isWorking">
    <p-spinner size="large"></p-spinner>
</div>

<div class="mainContainer">
    <div>
        <app-header *ngIf="showHeader"></app-header>
    </div>

    <div id="scroll-area" class="mainContainerBody">
        <router-outlet (activate)="onRouterActivate()"></router-outlet>
    </div>
</div>