import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MaterialOverviewComponent } from './components/material-overview/material-overview.component';
import { WorkflowOverviewComponent } from './components/workflow-overview/workflow-overview.component';
import { WorkflowDetailComponent } from './components/workflow-detail/workflow-detail.component';
import { AuthCallbackComponent } from './components/auth/auth-callback.component';
import { MaterialInspectComponent } from './components/material-inspect/material-inspect.component';
import { AdminOverviewComponent } from './components/admin-overview/admin-overview.component';
import { AuthGuard } from './helpers/auth.guard';
import { AdminDetailComponent } from './components/admin-detail/admin-detail.component';
import { ImportOverviewComponent } from './components/import-overview/import-overview.component';
import { ImportInspectComponent } from './components/import-inspect/import-inspect.component';
import { AuthSilentCallbackComponent } from "./components/auth/silent-callback.component";
import { AuthLogoutCallbackComponent } from "./components/auth/logout-callback.component";
import { UserguideComponent } from './components/userguide/userguide.component';
import { LoginComponent } from './components/login/login.component';



const routes: Routes = [
    { path: 'auth-callback', component: AuthCallbackComponent },
    { path: 'logout-callback', component: AuthLogoutCallbackComponent },
    { path: 'silent-callback', component: AuthSilentCallbackComponent },
    { path: 'login', component: LoginComponent},

    { path: 'inspect/:materialId/:materialRevision', component: MaterialInspectComponent, canActivate: [AuthGuard] },
    { path: 'workflows', component: WorkflowOverviewComponent, canActivate: [AuthGuard] },
    { path: 'workflow/create', component: WorkflowDetailComponent, canActivate: [AuthGuard] },
    { path: 'workflow/process/:id', component: WorkflowDetailComponent, canActivate: [AuthGuard] },
    { path: 'workflow/clone/:materialId/:materialRevision', component: WorkflowDetailComponent, canActivate: [AuthGuard] },
    { path: 'administration', component: AdminOverviewComponent, canActivate: [AuthGuard] },
    { path: 'administration/:id', component: AdminDetailComponent, canActivate: [AuthGuard] },
    { path: 'import', component: ImportOverviewComponent, canActivate: [AuthGuard] },
    { path: 'import-inspect', component: ImportInspectComponent, canActivate: [AuthGuard] },
    { path: 'userguide', component: UserguideComponent, canActivate: [AuthGuard] },


    { path: '', component: MaterialOverviewComponent, canActivate: [AuthGuard] }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
