import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HttpRequestService } from './services/request/httpRequest.service';
import { VersionService } from './services/version.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{

    // Show the header
    showHeader: boolean;

    // Constructor
    constructor(private router: Router, public httpRequestService: HttpRequestService, private translateService: TranslateService, versionService: VersionService) {
        versionService.Load();
        this.translateService.setDefaultLang('de');
        this.translateService.use('de');
    }

    ngOnInit() {
        if (window.top !== window.self) {
          window.top.location.href = window.self.location.href;
        }
      }
      

    // Called when the router changes
    onRouterActivate() {
        if (this.router.url.includes('login') || this.router.url.includes('logout')) {
            this.showHeader = false;
        } else {
            this.showHeader = true;
        }
    }
}
