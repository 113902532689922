<form [formGroup]="form">
    <p-grid>
        <!-- <ng-container *ngFor="let materialDefinition of materialDefinitionService.sortedMaterialDefinitions; index as i"> -->
        <p-grid-item size="12">
            <mat-expansion-panel class="overflow" [expanded]="true"
                *ngFor="let materialDefinition of materialDefinitionService.sortedMaterialDefinitionsDetail; index as i"
                size="12">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <p-grid-item size="12">
                            <p-headline variant="headline-4">{{'grouping.material.' + i | translate}}</p-headline>
                        </p-grid-item>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p-grid-item size="12" *ngFor="let attributeRowDefinition of materialDefinition">
                    <p-grid>
                        <ng-container *ngFor="let attributeDefinition of attributeRowDefinition">
                            <p-grid-item [size]="attributeDefinition.layout.colSpan"
                                [offset]="attributeDefinition.layout.offset" *ngIf="isVisible(attributeDefinition)">
                                <!-- Input -->
                                <p-text-field-wrapper *ngIf="attributeDefinition.displayType == DisplayType.Input"
                                    [label]="(('material.attribute.' + attributeDefinition.technicalName) | translate)"
                                    [state]="errorState(attributeDefinition) ? 'error' : 'none'"
                                    [message]="errorMessage(attributeDefinition) | translate">
                                    <input type="text" [formControlName]="attributeDefinition.technicalName"
                                        [required]="attributeDefinition.required" />
                                </p-text-field-wrapper>
                                <!-- Select -->
                                <p-select-wrapper *ngIf="attributeDefinition.displayType == DisplayType.Select"
                                    [label]="(('material.attribute.' + attributeDefinition.technicalName) | translate)"
                                    [state]="errorState(attributeDefinition) ? 'error' : 'none'"
                                    [message]="errorMessage(attributeDefinition) | translate">
                                    <!--[attr.disabled]="isEnable(attributeDefinition)[attr.test]="attributeDefinition.technicalName"-->
                                    <!--[(ngModel)]="selectedOption"-->
                                    <select [formControlName]="attributeDefinition.technicalName"
                                        [required]="attributeDefinition.required"
                                        (change)="onSelectChanged(attributeDefinition, $event.target)">
                                        <option [ngValue]="null">{{'common.select' | translate}}</option>
                                        <option *ngFor="let option of getSelectOptions(attributeDefinition)"
                                            [ngValue]="option">
                                            {{'material.selection.' + attributeDefinition.technicalName + '.' + option |
                                            translate}}
                                        </option>
                                    </select>
                                </p-select-wrapper>
                                <!-- Boolean -->
                                <p-select-wrapper *ngIf="attributeDefinition.displayType == DisplayType.Boolean"
                                    [label]="(('material.attribute.' + attributeDefinition.technicalName) | translate)"
                                    [state]="errorState(attributeDefinition) ? 'error' : 'none'"
                                    [message]="errorMessage(attributeDefinition) | translate">
                                    <select [formControlName]="attributeDefinition.technicalName"
                                        [required]="attributeDefinition.required">
                                        <option [ngValue]="null">{{'common.select' | translate}}</option>
                                        <option [ngValue]="true">{{'material.boolean.' +
                                            attributeDefinition.technicalName + '.true' | translate}}</option>
                                        <option [ngValue]="false">{{'material.boolean.' +
                                            attributeDefinition.technicalName + '.false' | translate}}</option>
                                    </select>
                                </p-select-wrapper>
                                <!-- Date -->
                                <p-text-field-wrapper *ngIf="attributeDefinition.displayType == DisplayType.Date"
                                    [label]="(('material.attribute.' + attributeDefinition.technicalName) | translate)"
                                    [state]="errorState(attributeDefinition) ? 'error' : 'none'"
                                    [message]="errorMessage(attributeDefinition) | translate">
                                    <input type="date" [formControlName]="attributeDefinition.technicalName"
                                        [required]="attributeDefinition.required" />
                                </p-text-field-wrapper>
                                <!-- List -->
                                <ng-container wrapper *ngIf="attributeDefinition.displayType == DisplayType.List">
                                    <p-text-field-wrapper
                                        [label]="(('material.attribute.' + attributeDefinition.technicalName) | translate)"
                                        [state]="errorState(attributeDefinition) ? 'error' : 'none'"
                                        [message]="errorMessage(attributeDefinition) | translate">
                                        <input type="text"
                                            (keydown.enter)="addListItem(attributeDefinition.technicalName, $event.target)"
                                            (focusout)="addListItem(attributeDefinition.technicalName, $event.target)"
                                            [required]="attributeDefinition.required" />
                                    </p-text-field-wrapper>
                                    <div *ngIf="form.get(attributeDefinition.technicalName).value?.length > 0"
                                        class="list-item-container">
                                        <div *ngFor="let item of form.get(attributeDefinition.technicalName).value"
                                            class="list-item">
                                            <p-button variant="tertiary" icon="close"
                                                (click)="removeListItem(attributeDefinition.technicalName, item)">
                                                {{item}}</p-button>
                                        </div>
                                    </div>
                                </ng-container>
                            </p-grid-item>
                        </ng-container>
                    </p-grid>
                </p-grid-item>
            </mat-expansion-panel>
        </p-grid-item>
    </p-grid>
</form>