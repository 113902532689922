<div>
    <div class="content-div" *ngIf="!currentImportFileStateId">
        <mat-card appearance="outlined" class="upload-card">
            <mat-card-title>{{'workflow.upload' | translate}}</mat-card-title>
            <mat-card-content *ngIf="!file">
                <p>{{'workflow.uploadFile' | translate}}</p>
                <input class="file" type="file" accept=".xlsx" (change)="getFile($event)" />
            </mat-card-content>
            <mat-card-content *ngIf="file" (click)="file = undefined">
                <p-text>{{'import.filename' | translate}}: {{file.name}} <br /></p-text>
            </mat-card-content>
            <mat-card-actions *ngIf="file">
                <p-button class="workflow-activities" style="margin-left: 10px" [variant]="'primary'"
                    (click)="uploadFileAsync()">Import starten</p-button>
            </mat-card-actions>
        </mat-card>
    </div>
    <!-- <div class="content-div" *ngIf="file">
        <mat-card class="workflow-card">
            <mat-card-title>Workflow</mat-card-title>
            <mat-card-actions>
                <form class="workflow-activities" #userForm="ngForm" action="">
                    <mat-form-field appearance="fill">
                        <mat-label>{{'workflow.approvers' | translate}}</mat-label>
                        <mat-select [(ngModel)]="approverInput" (ngModelChange)="getApproverInput(approverInput)"
                            name="approver">
                            <mat-option [value]="null">{{'common.select' | translate}}</mat-option>
                            <mat-option *ngFor="let approver of importApprovers" [value]="approver">
                                {{approver.name}}
                            </mat-option>
                        </mat-select> 
                    </mat-form-field>
                </form>
                <p-button class="workflow-activities" style="margin-left: 25px" [variant]="'primary'"
                    (click)="uploadFileAsync()" [disabled]="isDisabled">{{'workflow.start' | translate}}
                </p-button>
            </mat-card-actions>
        </mat-card>
    </div> -->
    <div class="content-div" *ngIf="currentImportFileStateId">
        <app-import-material-temp-control class="table-container" #importMaterialsControl
            *ngIf="currentImportFileStateId" [importFileStateId]="currentImportFileStateId" enabledInputs="">
        </app-import-material-temp-control>
        <mat-card appearance="outlined" *ngIf="isVisible">
            <mat-card-title>{{'workflow.upload' | translate}}</mat-card-title>
            <mat-card-action>
                <form class="workflow-activities" #userForm="ngForm" action="">
                    <mat-form-field appearance="fill">
                        <mat-label>{{'workflow.approvers' | translate}}</mat-label>
                        <mat-select [(ngModel)]="approverInput" (ngModelChange)="getApproverInput(approverInput)"
                            name="approver">
                            <mat-option [value]="null">{{'common.select' | translate}}</mat-option>
                            <mat-option *ngFor="let approver of importApprovers" [value]="approver">
                                {{approver.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>
                <p-button class="workflow-activities" style="margin-left: 25px" [variant]="'primary'"
                    (click)="setWorkflowApproverAsync()" [disabled]="isDisabled">{{'workflow.start' | translate}}
                </p-button>
                <p-button class="workflow-activities" style="margin-left: 25px" [variant]="'primary'"
                    (click)="abortImportAsync()">{{'workflow.abort' | translate}}
                </p-button>
            </mat-card-action>
        </mat-card>
        <p-button *ngIf="!isVisible" [routerLink]="['/import-inspect']" style="margin-top:1rem;">
            {{'import.nextToMyImports' | translate}}</p-button>
    </div>
</div>